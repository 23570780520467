import React from 'react';
import SttTextAreaComponent from "./SttTextAreaComponent";

export default class Concept extends SttTextAreaComponent {

    constructor(props) {
        super(props);
        this.identifier = props.identifier;
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        return (
            <div className="row group-hr" data-cfw-equalize-group="concept">
                <div className="col-12">
                    {super.render()}
                </div>
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }
}
