import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import Arrow from './Arrow';
import AutocompleteArrow from './AutocompleteArrow';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import {codeMirrorMultilineConfig} from '../codeMirrorConfig';

/**
 * List of topics within a Unit in the Unit Organizer.
 */
export default class TopicList extends ListComponent {

    constructor(props) {
        super(props);
        this.identifier = "topic";
        this.canBeEmpty = false;
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const itemList = this.getContentList();
        let itemsHTML = [];
        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)) {
                    itemsHTML.push(<Topic key={key}
                                          id={key}
                                          parentNode={this.getCurrentNode()}
                                          deleteMethod={this.handleDeleteItem}
                                          canDelete={this.state.canDelete}/>);
                }
            }
        }

        const addButton = this.context.canEdit ?
            <div className="row">
                <div className="col-12">
                    <button className="btn btn-corgi btn-corgi-icon" onClick={this.handleAddItem}>
                        <span className="icon"><span className="fa fa-plus" aria-hidden="true"/></span>
                        Add a New Main Idea
                    </button>
                </div>
            </div>
            : null;

        return (
            <div>
                {itemsHTML}
                {addButton}
            </div>
        );
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            connector: "",
            topic: "",
            question: "",
            orgtype: ""
        })
    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.identifier;
    }
}


/**
 * A single topic in the list.
 */
class Topic extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orgtype: ""
        };

        this.topicId = uniqueId();
        this.questionId = uniqueId();
        this.selectId = uniqueId();
        this.deleteModalId = uniqueId();

        this.handleOrgTypeSelection = this.handleOrgTypeSelection.bind(this);
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const type = this.context.fileType;

        let connector, orgtype;
        if (this.context.canEdit) {
            connector = <AutocompleteArrow className="col-md-4 pb-0_5"
                                           identifier="connector"
                                           placeholder={T.translate(type + ".connector.placeholder")}
                                           parentNode={this.getCurrentNode()}
                                           label="Relationship"
                                           autocompleteOptions={['Originating from', 'Resulted in', 'Affected by', 'Characterized by', 'Made up of']}/>;
            orgtype = <select className="form-control"
                              id={this.selectId}
                              value={this.state.orgtype}
                              onChange={this.handleOrgTypeSelection}>
                <option value="">Pick one...</option>
                <option value="comparison">Compare and Contrast</option>
                <option value="cause-effect">Cause and Effect</option>
                <option value="question-exp">Question and Answer</option>
            </select>;
        } else {
            // Simple, read-only versions of these controls.
            connector = <Arrow className="col-md-4 pb-0_5"
                               identifier="connector"
                               parentNode={this.getCurrentNode()}
                               placeholder={T.translate(type + ".connector.placeholder")}
                               label="Relationship"
                               default=""/>;
            orgtype = <div className="form-control">
                <div id={this.selectId}/>
            </div>
        }

        const link = (this.state.orgtype)
            ? <a href={'/?type=' + this.state.orgtype} role="button" className="link-add">
                Create {T.translate(this.state.orgtype + '.title')}
            </a>
            : null;
        return (
            <div className="group-hr" role="group" aria-label="Main idea item">
                <div className="row row-trash">
                    {connector}
                </div>
                <div className="row row-trash">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor={this.topicId}
                                   className="sr-only-md-up">{T.translate(type + ".mainIdea.label")}</label>
                            <div className="text-area firepad-form form-control" id={this.topicId}></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor={this.questionId}
                                   className="sr-only-md-up">{T.translate(type + ".questions.label")}</label>
                            <div className="text-area firepad-form form-control" id={this.questionId}></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor={this.selectId}
                                   className="sr-only-md-up">{T.translate(type + ".orgtype.label")}</label>
                            {orgtype}
                            {link}
                        </div>
                    </div>
                    <DeleteButton modalId={this.deleteModalId} tooltip="Delete Row" canDelete={this.props.canDelete}/>
                </div>
                <DeleteModal id={this.deleteModalId} text="Really delete this main idea row?"
                             method={this.handleDeleteButton}/>
            </div>
        );
    }

    componentDidMount() {
        const type = this.context.fileType;

        let topicPlaceholder = T.translate(type + '.mainIdea.placeholder');
        let firepadTopicRef = window.firebase.database().ref(this.getCurrentNode() + "/topic");
        let codeMirrorTopic = window.CodeMirror(document.getElementById(this.topicId),
            codeMirrorMultilineConfig(!this.context.canEdit, topicPlaceholder));
        window.Firepad.fromCodeMirror(firepadTopicRef, codeMirrorTopic, {
            richTextShortcuts: false,
            richTextToolbar: false
        });

        let questionPlaceholder = T.translate(type + '.questions.placeholder');
        let firepadQuestionRef = window.firebase.database().ref(this.getCurrentNode() + "/question");
        let codeMirrorQues = window.CodeMirror(document.getElementById(this.questionId),
            codeMirrorMultilineConfig(!this.context.canEdit, questionPlaceholder));
        window.Firepad.fromCodeMirror(firepadQuestionRef, codeMirrorQues, {
            richTextShortcuts: false,
            richTextToolbar: false
        });

        this.orgTypeRef = window.firebase.database().ref(this.getCurrentNode() + "/orgtype");
        if (this.context.canEdit) {
            this.orgTypeRef.on("value", data => {
                if (data.val() !== this.state.orgtype) {
                    this.setState({
                        orgtype: data.val()
                    })
                }
            });
        } else {
            let codeMirrorType = window.CodeMirror(document.getElementById(this.selectId),
                codeMirrorMultilineConfig(!this.context.canEdit));
            window.Firepad.fromCodeMirror(firepadQuestionRef, codeMirrorType, {
                richTextShortcuts: false,
                richTextToolbar: false
            });
        }

    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.props.id;
    }

    // Called when user changes the pulldown menu of organizer types
    handleOrgTypeSelection(event) {
        const newValue = event.target.value;
        this.setState({orgtype: newValue}, () => {
            this.orgTypeRef.set(newValue)
        });
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }

}

Topic.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};

Topic.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};
