import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import InfoButton from './InfoButton';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import $ from 'jquery';
import {codeMirrorSingleLineConfig} from '../codeMirrorConfig';

/** (Abstract) Component for a list of characteristics.
 *
 * Subclasses must set this.identifier.
 */
export default class Characteristics extends ListComponent {

    constructor(props) {
        super(props);
        this.identifier = props.identifier;
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const type = this.context.fileType;
        const itemList = this.getContentList();
        let itemsHTML = [];
        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)) {
                    itemsHTML.push(<Item key={key}
                                         id={key}
                                         identifier={this.identifier}
                                         deleteMethod={this.handleDeleteItem}/>);
                }
            }
        }
        const addButton = this.context.canEdit ?
            <div className="pb-1">
                <button className="btn btn-corgi btn-corgi-icon" onClick={this.handleAddItem}>
                    <span className="icon"><span className="fa fa-plus" aria-hidden="true"/></span>
                    Add a Characteristic
                </button>
            </div>
            : null;

        return (
            <div>
                <div className="row">
                    <div className="col mb-0_5">
                        <h4 className="d-inline mb-0"><span className="hnum">{this.props.stepnum}</span> Characteristics
                        </h4>
                        <InfoButton type={type} identifier={this.identifier}/>
                    </div>
                </div>

                {itemsHTML}
                {addButton}
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            text: "",
            status: ""
        });
    }

    componentDidMount() {
        let listItemRefs = window.firebase.database().ref(this.getCurrentNode());
        listItemRefs.once("value").then(list => {
            if (!this.canBeEmpty) {
                if (!list.exists() || Object.keys(list.val()).length <= 0)
                    this.handleAddItem();
            }
            this.setState({canDelete: this.checkDeleteLegality(list.val())});
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.unpairedLength !== prevProps.unpairedLength ||
            this.props.pairedLength !== prevProps.pairedLength) {
            this.setState({
                canDelete: this.checkDeleteLegality({...this.props.pairedCharacteristics, ...this.props.unpairedCharacteristics})
            })
        }
    }

    checkDeleteLegality(newItems) {
        if (!this.context.canEdit || !newItems)
            return false;
        if (this.canBeEmpty)
            return true;
        return (Object.keys(newItems).length > 1);
    }

    getContentList() {
        return {...this.props.pairedCharacteristics, ...this.props.unpairedCharacteristics};
    }
}

Characteristics.propTypes = {
    stepnum: PropTypes.string.isRequired,
    unpairedCharacteristics: PropTypes.object,
    pairedCharacteristics: PropTypes.object,
    unpairedLength: PropTypes.number,
    pairedLength: PropTypes.number
};

/**
 *  Component for single Characteristic.
 *  These are text fields with an additional icon, which can replace the delete button, indicating status.
 */
class Item extends Component {

    constructor(props) {
        super(props);
        this.id = uniqueId();
        this.deleteButtonId = uniqueId();
        this.deleteModalId = uniqueId();
        this.state = {
            status: ""
        };
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const status = this.state.status;
        let icon;
        if (status === 'like')
            icon = <span className="icon-match text-green-500" title="Matched as Like"><span aria-hidden="true">=</span></span>;
        else if (status === 'unlike')
            icon =
                <span className="icon-match text-red-500" title="Matched as Unlike"><span aria-hidden="true">&ne;</span></span>;
        else
            icon = <DeleteButton tooltip="Delete Characteristic" modalId={this.deleteModalId}
                                 canDelete={this.context.canEdit}/>;
        return (
            <div className="row row-trash">
                <div className="col-12">
                    <div className="form-group">
                        <div className="firepad-form form-control">
                            <div id={this.id}/>
                        </div>
                    </div>
                </div>
                {icon}
                <DeleteModal id={this.deleteModalId} text="Really delete this characteristic?"
                             method={this.handleDeleteButton}/>
            </div>
        );
    }

    componentWillUnmount() {
        window.firebase.database().ref(this.getCurrentNode() + "/text").off();
        window.firebase.database().ref(this.getCurrentNode() + "/status").off()
    }

    componentDidMount() {
        const type = this.context.fileType;
        let placeholder = T.translate(type + "." + this.props.identifier + ".placeholder");
        let firepadcharRef = window.firebase.database().ref(this.getCurrentNode() + "/text");
        let codeMirrorChar = window.CodeMirror(document.getElementById(this.id),
            codeMirrorSingleLineConfig(!this.context.canEdit, placeholder));
        window.Firepad.fromCodeMirror(firepadcharRef, codeMirrorChar, {
            richTextShortcuts: false,
            richTextToolbar: false
        });
        let statusRef = window.firebase.database().ref(this.getCurrentNode() + "/status");
        statusRef.on("value", data => {
            let status = data.val();
            if (this.state.status !== status) {
                this.setState({
                    status: status
                });
            }
        });

        // Need to initialize since these can be created on the fly.
        const button = $('#' + this.deleteButtonId);
        if (button.CFW_Modal) {
            button.CFW_Modal();
        }
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.props.identifier + "/" + this.props.id;
    }
}

Item.propTypes = {
    identifier: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    deleteMethod: PropTypes.func.isRequired,
};

Item.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};
