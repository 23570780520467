import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uniqueId from '../uniqueId';
import $ from 'jquery';

export default class DeleteButton extends Component {


    render() {
        this.id = uniqueId();
        return (
            <button id={this.id} type="button" className="btn btn-trash" title={this.props.tooltip}
                    data-cfw="modal" data-cfw-modal-toggle={'#'+this.props.modalId}
                    disabled={!this.props.canDelete} >
                <span className="fa fa-trash-o" aria-hidden="true"/>
            </button>
        );
    }

    componentDidMount() {
        const button = $('#' + this.id);
        if (button.CFW_Modal) {
            button.CFW_Modal();
        }
    }
}

DeleteButton.propTypes = {
    modalId: PropTypes.string.isRequired,
    tooltip : PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired
};
