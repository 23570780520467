import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ComponentHeader from './ComponentHeader';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import {codeMirrorMultilineConfig} from '../codeMirrorConfig';

/**
 * An abstract component for simple items that just contain a single TextArea
 */
export default class TextAreaComponent extends Component {

    constructor(props) {
        super(props);
        this.domId = uniqueId();
        // CSS class that will be set for the component's label
        this.labelClassName = "sr-only";
        // CSS class for the component's form group
        this.formClassName = "form-group";
        //this.onChangeHandle = this.onChangeHandle.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.firepad = null;
        this.codeMirror = null;
    }

    render() {
        const label = T.translate(this.context.fileType + '.' + this.identifier + '.label');
        return (
            <div>
                {this.renderHeader()}
                <div className={this.formClassName}>
                    <label htmlFor={this.domId} className={this.labelClassName}>{label}</label>
                    <div className="stt-wrapper">
                        {this.renderInput()}
                        <button type="button" className="stt-btn" data-cast="stt" data-target="#resq">
                            <span className="fa fa-microphone" aria-hidden="true"></span>
                            <span className="sr-only">Use Speech to Text</span>
                        </button>
                    </div>
                    </div>
            </div>
        );
    }

    componentDidMount() {
        const placeholder = T.translate(this.context.fileType + '.' + this.identifier + '.placeholder');
        let firepadRef = window.firebase.database().ref(this.getCurrentNode());
        this.codeMirror = window.CodeMirror(document.getElementById(this.domId),
            codeMirrorMultilineConfig(!this.context.canEdit, placeholder));
        this.firepad = window.Firepad.fromCodeMirror(firepadRef, this.codeMirror, {
            richTextShortcuts: false,
            richTextToolbar: false
        });
    }

    renderHeader() {
        return (
            <ComponentHeader stepnum={this.props.stepnum} type={this.context.fileType} identifier={this.identifier}/>);
    }

    renderInput() {
        return (<div id={this.domId} className="text-area firepad-form form-control form-control-lg"/>)
    }

}

TextAreaComponent.propTypes = {
    stepnum: PropTypes.string.isRequired,
    focused: PropTypes.bool,
    labelClassName: PropTypes.string
};

TextAreaComponent.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};
