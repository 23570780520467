import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import uniqueId from '../uniqueId';
import $ from 'jquery';
import T from 'i18n-react';
import {codeMirrorMultilineConfig} from '../codeMirrorConfig';

/**
 * (Abstract) Component for the "Like/Unlike Characteristics & Categories" sections.
 *
 * Subclass must set various this. properties to set messages and identifiers.
 *
 * Component is primarily a list of characteristic + category items, but this is not stored as a separate list,
 * it is based on filtering the full lists of characteristics.
 * In addition to that list, it includes a "Find (Unlike) Pair" Button, and the modal that is shown for that
 * (including the list of all UNpaired items).
 */
export default class PairedCharacteristics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            findButtonsActive: false, // whether the "Find (Un)Like Pair" button is currently enabled
            pairButtonActive: false, // whether the "Pair" button in the modal is currently enabled.
            checkedA: null,
            checkedB: null,
            pairedItems: {},
            characteristics1: {},
            characteristics2: {}
        };

        this.sourceA = "characteristics1";
        this.sourceB = "characteristics2";
        this.buttonId = uniqueId();
        this.modalId = uniqueId();

        this.handleOpen = this.handleOpen.bind(this);
        this.handleChangeA = this.handleChangeA.bind(this);
        this.handleChangeB = this.handleChangeB.bind(this);
        this.isPairingPossible = this.isPairingPossible.bind(this);
        this.checkPairButton = this.checkPairButton.bind(this);
        this.handlePair = this.handlePair.bind(this);
        this.makePair = this.makePair.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
        this.getBaseNode = this.getBaseNode.bind(this);
    }

    componentWillMount() {
        this.setState({findButtonsActive: this.isPairingPossible()});
    }

    render() {
        const listA = this.props.unpairedCharacteristics1;
        const listB = this.props.unpairedCharacteristics2;
        const listPairedItems = this.props.pairedCharacteristics;
        let pairedItems = [], modalItemsA = [], modalItemsB = [];

        if (listPairedItems) {
            for (let key in listPairedItems) {
                if (listPairedItems.hasOwnProperty(key)) {
                    let pairedItem = listPairedItems[key];
                    pairedItems.push(<CharPair key={key}
                                               pairedID={key}
                                               idA={pairedItem["characteristic1"]}
                                               idB={pairedItem["characteristic2"]}
                                               showBoth={this.showBoth}
                                               catIdentifier={this.catIdentifier}
                                               charIdentifier={this.charIdentifier}/>);
                }
            }
        }

        if (listA) {
            for (let key in listA) {
                if (listA.hasOwnProperty(key)) {
                    modalItemsA.push(<CharOption key={key}
                                                 id={key}
                                                 group="groupA"
                                                 parentNode={this.getBaseNode() + "/" + this.sourceA}
                                                 checked={this.state.checkedA}
                                                 change={this.handleChangeA}/>);
                }
            }
        }
        if (listB) {
            for (let key in listB) {
                if (listB.hasOwnProperty(key)) {
                    modalItemsB.push(<CharOption key={key}
                                                 id={key}
                                                 group="groupB"
                                                 parentNode={this.getBaseNode() + "/" + this.sourceB}
                                                 checked={this.state.checkedB}
                                                 change={this.handleChangeB}/>);
                }
            }
        }
        return (
            <div>
                {pairedItems}
                {this.context.canEdit ?
                    <div className="pb-1">
                        <button id={this.buttonId} type="button" disabled={!this.state.findButtonsActive}
                                onClick={this.handleOpen}
                                className="btn btn-corgi btn-corgi-icon"
                                data-cfw="modal" data-cfw-modal-toggle={'#' + this.modalId}>
                            <span className="icon"><span className="fa fa-plus" aria-hidden="true"/></span>
                            {this.buttonText}
                        </button>
                    </div>
                    : null
                }
                {this.context.canEdit ?
                    <div id={this.modalId} className="modal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <h2 className="mb-1">{this.modalTitle}</h2>

                                    <div className="row mb-1">
                                        <div className="col-6">
                                            <div className="h4 mb-1">Characteristics A</div>
                                            {modalItemsA}
                                        </div>
                                        <div className="col-6">
                                            <div className="h4 mb-1">Characteristics B</div>
                                            {modalItemsB}
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button type="button" onClick={this.handlePair}
                                                disabled={!this.state.pairButtonActive}
                                                className="btn btn-corgi-lg btn-corgi-c2 mr-0_5">Add Pair
                                        </button>
                                        <button type="button" className="btn btn-corgi-lg ml-0_5"
                                                data-cfw-dismiss="modal">Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        );

    }


    // Check whether the conditions are met for the "Pair" button to be active.
    // True if there is at least one non-blank, non-paired item in each column.
    isPairingPossible() {
        const listA = this.props.unpairedCharacteristics1;
        const listB = this.props.unpairedCharacteristics2;
        return listA && listB;
    }

    handleOpen() {
        this.setState({
            pairButtonActive: false,
            checkedA: null,
            checkedB: null
        });
    }

    handleChangeA(event) {
        const a = event.target.value;
        this.setState({checkedA: a});
        this.checkPairButton(a, this.state.checkedB);
    }

    handleChangeB(event) {
        const b = event.target.value;
        this.setState({checkedB: b});
        this.checkPairButton(this.state.checkedA, b);
    }

    checkPairButton(a, b) {
        if (a != null && b != null && !this.state.pairButtonActive)
            this.setState({pairButtonActive: true});
    }

    handlePair() {
        const idA = this.state.checkedA, idB = this.state.checkedB;
        if (idA === null || idB === null)
            return;

        this.makePair(idA, idB);
        $('#' + this.buttonId).CFW_Modal('hide');
        this.setState({
            pairButtonActive: false,
            checkedA: null,
            checkedB: null
        });
    }

    makePair(idA, idB) {
        let characteristic1Ref = window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + idA);
        let characteristic2Ref = window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + idB);
        characteristic1Ref.update({
            status: this.myStatus
        }, () => {
            characteristic2Ref.update({
                status: this.myStatus
            }, () => {
                let pairsRef = window.firebase.database().ref(this.getCurrentNode()).push();
                pairsRef.set({
                    "characteristic1": idA,
                    "characteristic2": idB,
                });
            })
        });
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/pairs/" + this.charIdentifier;
    }

    getBaseNode() {
        return this.context.fileType + "/" + this.context.fileId;
    }

}

PairedCharacteristics.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

/**
 * A single row in the (Un)like Characteristics listing,
 * consisting of the (read-only) characteristic and an editable Category text box,
 * with a "delete" button that just unpairs the items.
 */
class CharPair extends Component {

    constructor(props) {
        super(props);
        this.handleUnpair = this.handleUnpair.bind(this);
        this.getBaseNode = this.getBaseNode.bind(this);
        this.textFieldId = uniqueId();
        this.deleteModalId = uniqueId();
        this.state = {
            textA: "",
            textB: "",
        }
    }

    render() {
        const type = "comparison";
        return (
            <div className="row row-trash group-hr">
                <div className={this.props.showBoth ? "col-md-3" : "col-md-7"}>
                    <div className="form-group">
                        <label
                            className="sr-only-md-up">{T.translate(type + "." + this.props.charIdentifier + ".singleLabel")}</label>
                        <p className="form-control-static">{this.state.textA}</p>
                    </div>
                </div>
                {this.props.showBoth ?
                    <div className="col-md-4">
                        <div className="form-group">
                            <label
                                className="sr-only-md-up">{T.translate(type + "." + this.props.charIdentifier + ".secondaryLabel")}</label>
                            <p className="form-control-static">{this.state.textB}</p>
                        </div>
                    </div>
                    : null}
                <div className="col-md-5">
                    <div className="form-group">
                        <label htmlFor={this.textFieldId}
                               className="sr-only-md-up">{T.translate(type + "." + this.props.catIdentifier + ".singleLabel")}</label>
                        <div className="text-area firepad-form form-control" id={this.textFieldId}></div>
                    </div>
                </div>
                <DeleteButton tooltip="Remove pairing" modalId={this.deleteModalId} canDelete={this.context.canEdit}/>
                <DeleteModal id={this.deleteModalId} method={this.handleUnpair}
                             text={T.translate(type + "." + this.props.charIdentifier + ".delete")} yes="Unpair"/>
            </div>
        );
    }

    getBaseNode() {
        return this.context.fileType + "/" + this.context.fileId;
    }

    componentWillUnmount() {
        //Stop listen to this pair change
        window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/text").off();
        window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + this.props.idB + "/text").off();
        window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/status").off();
        window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + this.props.idB + "/status").off();
        window.firebase.database().ref(this.getBaseNode() + "/pairs/" + this.props.charIdentifier + "/" + this.props.pairedID).off();
    }

    componentDidMount() {
        const type = "comparison";
        let placeholder = T.translate(type + "." + this.props.catIdentifier + ".placeholder");
        let firepadCategoryRef = window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/category");
        let codeMirrorCategory = window.CodeMirror(document.getElementById(this.textFieldId),
            codeMirrorMultilineConfig(!this.context.canEdit, placeholder));
        window.Firepad.fromCodeMirror(firepadCategoryRef, codeMirrorCategory, {
            richTextShortcuts: false,
            richTextToolbar: false
        });

        let firepadcharARef = window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/text");
        let firepadcharBRef = window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + this.props.idB + "/text");
        firepadcharARef.on("value", () => {
            let firepadHeadlessA = new window.Firepad.Headless(firepadcharARef);
            firepadHeadlessA.getText(text => {
                if (this.state && this.state.textA !== text) {
                    this.setState({
                        textA: text
                    });
                }
                firepadHeadlessA.dispose();
            });
        });

        firepadcharBRef.on("value", () => {
            let firepadHeadlessB = new window.Firepad.Headless(firepadcharBRef);
            firepadHeadlessB.getText(text => {
                if (this.state && this.state.textB !== text) {
                    this.setState({
                        textB: text
                    });
                }
                firepadHeadlessB.dispose();
            });
        });

    }

    handleUnpair() {
        //Set status value to empty and delete pairs database in realtimedb
        window.firebase.database().ref(this.getBaseNode() + "/characteristics1/" + this.props.idA + "/status").set("", () => {
            window.firebase.database().ref(this.getBaseNode() + "/characteristics2/" + this.props.idB + "/status").set("", () => {
                window.firebase.database().ref(this.getBaseNode() + "/pairs/" + this.props.charIdentifier + "/" + this.props.pairedID).remove();
            });
        });
    }

}

CharPair.propTypes = {
    idA: PropTypes.string.isRequired,
    idB: PropTypes.string.isRequired,
    pairedID: PropTypes.string.isRequired,
    showBoth: PropTypes.bool.isRequired,
    charIdentifier: PropTypes.string.isRequired,
    catIdentifier: PropTypes.string.isRequired,
};

CharPair.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

/**
 * A radio-button option in the modal window for choosing a pair of items.
 */
class CharOption extends Component {

    constructor(props) {
        super(props);
        this.getCurrentNode = this.getCurrentNode.bind(this);
        this.state = {
            text: ""
        }
    }

    render() {
        return (
            <div className="form-check">
                <label className="form-check-label">
                    <input className="form-check-input" type="radio" name={this.props.group} value={this.props.id}
                           onChange={this.props.change} checked={this.props.id === this.props.checked}/>
                    {this.state.text}
                </label>
            </div>
        );
    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.props.id + "/text";
    }

    componentWillUnmount() {
        window.firebase.database().ref(this.getCurrentNode()).off();
    }

    componentDidMount() {
        let firepadcharRef = window.firebase.database().ref(this.getCurrentNode());
        firepadcharRef.on("value", () => {
            let firepadHeadless = new window.Firepad.Headless(firepadcharRef);
            firepadHeadless.getText(text => {
                if (this.state.text !== text) {
                    this.setState({
                        text: text
                    });
                }
                firepadHeadless.dispose();
            })
        })

    }
}

CharOption.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.string,
    group: PropTypes.string.isRequired,
    parentNode: PropTypes.string.isRequired
};
