import React, {Component} from 'react';

export default class InvalidIdError extends Component {

    render() {
        return(
            <main id="main_container" className="container-fluid">
                <div className="row">
                    <div className="col text-center mt-2">
                        <h1 className="h4 text-body mb-2">The file ID is invalid</h1>
                    </div>
                </div>
            </main>
        )
    }
}