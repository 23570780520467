import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Concept from "./Concept";
import Characteristics from "./Characteristics";
import LikeCharacteristics from "./LikeCharacteristics";
import UnlikeCharacteristics from "./UnlikeCharacteristics";
import T from "i18n-react";
import InfoButton from "./InfoButton";

export default class CharacteristicsWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            unlikePairedCharacteristics: {},
            likePairedCharacteristics: {},
            pairedCharacteristics1: {},
            pairedCharacteristics2: {},
            unpairedCharacteristics1: {},
            unpairedCharacteristics2: {}
        };

        this.sourceA = "characteristics1";
        this.sourceB = "characteristics2";
        this.getBaseNode = this.getBaseNode.bind(this);
    }

    render() {
        return (
            <div>
                <div className="row gridline gridline-hr gridline-md-vr"
                     data-cfw="equalize" data-cfw-equalize-target="concept" data-cfw-equalize-row="true">
                    <div className="col-md-6 pt-1 gridline-item gridline-hr">
                        <Concept identifier="concept1" stepnum="1a"/>
                        <Characteristics unpairedCharacteristics={this.state.unpairedCharacteristics1}
                                         pairedCharacteristics={this.state.pairedCharacteristics1}
                                         unpairedLength={Object.keys(this.state.unpairedCharacteristics1).length}
                                         pairedLength={Object.keys(this.state.pairedCharacteristics1).length}
                                         stepnum="3a"
                                         identifier="characteristics1"/>
                    </div>

                    <div className="col-md-6 pt-1 gridline-item gridline-hr gridline-step2">
                        <Concept identifier="concept2" stepnum="1b"/>
                        <Characteristics unpairedCharacteristics={this.state.unpairedCharacteristics2}
                                         pairedCharacteristics={this.state.pairedCharacteristics2}
                                         unpairedLength={Object.keys(this.state.unpairedCharacteristics2).length}
                                         pairedLength={Object.keys(this.state.pairedCharacteristics2).length}
                                         stepnum="3b"
                                         identifier="characteristics2"/>
                    </div>
                </div>

                <CCHeader s1="4" s2="5" title="Like Characteristics and Categories"
                          id1="likecharacteristics" id2="likecategories"/>
                <LikeCharacteristics unpairedCharacteristics1={this.state.unpairedCharacteristics1}
                                     unpairedCharacteristics2={this.state.unpairedCharacteristics2}
                                     pairedCharacteristics={this.state.likePairedCharacteristics}/>

                <CCHeader s1="6" s2="7" title="Unlike Characteristics and Categories"
                          id1="unlikecharacteristics" id2="unlikecategories"/>
                <UnlikeCharacteristics unpairedCharacteristics1={this.state.unpairedCharacteristics1}
                                       unpairedCharacteristics2={this.state.unpairedCharacteristics2}
                                       pairedCharacteristics={this.state.unlikePairedCharacteristics}/>
            </div>
        );
    }

    getBaseNode() {
        return this.context.fileType + "/" + this.context.fileId;
    }

    componentDidMount() {
        this.char1BaseRef = this.getBaseNode() + "/" + this.sourceA;
        this.char2BaseRef = this.getBaseNode() + "/" + this.sourceB;
        let likeRef = this.getBaseNode() + "/pairs/likecharacteristics";
        let unlikeRef = this.getBaseNode() + "/pairs/unlikecharacteristics";
        this.fetchAndListenUnPairedItems(this.char1BaseRef, "unpairedCharacteristics1");
        this.fetchAndListenUnPairedItems(this.char2BaseRef, "unpairedCharacteristics2");
        this.fetchAndListenPairedItems(likeRef, "likePairedCharacteristics");
        this.fetchAndListenPairedItems(unlikeRef, "unlikePairedCharacteristics");
    }

    fetchAndListenPairedItems(baseRef, typePair) {
        let pairedNodes = window.firebase.database().ref(baseRef);
        pairedNodes.on("child_added", data => {
            let pairedKey = data.key;
            let pairedVal = data.val();
            let char1Ref = this.char1BaseRef + "/" + pairedVal["characteristic1"];
            window.firebase.database().ref(char1Ref).once("value", data => {
                let charA = data.val();
                let keyA = data.key;
                let char2Ref = this.char2BaseRef + "/" + pairedVal["characteristic2"];
                window.firebase.database().ref(char2Ref).once("value", data => {
                    let charB = data.val();
                    let keyB = data.key;
                    let newState = this.state;
                    newState[typePair][pairedKey] = pairedVal;
                    newState.pairedCharacteristics1[keyA] = charA;
                    newState.pairedCharacteristics2[keyB] = charB;
                    this.setState(newState);
                })
            });
        });
        pairedNodes.on("child_removed", data => {
            let pairedKey = data.key;
            let pairedVal = data.val();
            let char1Ref = this.char1BaseRef + "/" + pairedVal["characteristic1"];
            window.firebase.database().ref(char1Ref).once("value", data => {
                let keyA = data.key;
                let char2Ref = this.char2BaseRef + "/" + pairedVal["characteristic2"];
                window.firebase.database().ref(char2Ref).once("value", data => {
                    let keyB = data.key;
                    let newState = this.state;
                    delete newState[typePair][pairedKey];
                    delete newState.pairedCharacteristics1[keyA];
                    delete newState.pairedCharacteristics2[keyB];
                    this.setState(newState);
                })
            });
        })
    }

    fetchAndListenUnPairedItems(baseRef, typeCharacter) {
        let listItemRefs = window.firebase.database().ref(baseRef).orderByChild("status").endAt("");
        listItemRefs.on("child_added", data => {
            let newState = this.state;
            newState[typeCharacter][data.key] = data.val();
            this.setState(newState);
        });
        listItemRefs.on("child_removed", data => {
            let newState = this.state;
            delete newState[typeCharacter][data.key];
            this.setState(newState);
        });
        listItemRefs.on("child_changed", data => {
            let newState = this.state;
            newState[typeCharacter][data.key] = data.val();
            this.setState(newState);
        })
    }
}

CharacteristicsWrapper.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

class CCHeader extends Component {

    render() {
        const type = "comparison";
        const t1 = T.translate(type + "." + this.props.id1 + ".label");
        const t2 = T.translate(type + "." + this.props.id2 + ".label");
        return (
            <div className="row gridline gridline-hr">
                <div className="col-12">
                    <div className="row row-trash">
                        <div className="col-12">
                            <h3 className="sr-only-md-up pt-1"><span className="hnum"><small>{this.props.s1}&middot;{this.props.s2}</small></span> {this.props.title}
                            </h3>
                        </div>
                        <div className="col-6 col-md-7 pt-md-1">
                            <div className="row">
                                <div className="col mb-0_5">
                                    <h3 className="d-inline mb-0 hide-sm-down" aria-hidden="true"><span
                                        className="hnum">{this.props.s1}</span> {t1}</h3>
                                    <InfoButton type={type} identifier={this.props.id1}/>
                                    <span className="small d-inline-block valign-middle pl-0_5 hide-md-up"
                                          aria-hidden="true">More information<br/> about {t1}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-5 pt-md-1">
                            <div className="row">
                                <div className="col mb-0_5">
                                    <h3 className="d-inline mb-0 hide-sm-down" aria-hidden="true"><span
                                        className="hnum">{this.props.s2}</span> {t2}</h3>
                                    <InfoButton type={type} identifier={this.props.id2}/>
                                    <span className="small d-inline-block valign-middle pl-0_5 hide-md-up"
                                          aria-hidden="true">More information<br/> about {t2}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
