import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VideoModalButton from './VideoModalButton';
import LinkingStepsLink from './LinkingStepsLink';
import T from 'i18n-react';
//import $ from 'jquery';

/** Creates a popover that will be triggered by the "More Information" button in the header.
 * The header refers to this component by its ID,  "PageInfoPopover", so that must be preserved.
 */
export default class PageInfoPopover extends Component {

    example(pdf,topic) {
        return(
            pdf ?
            <p>
                <a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "/example/" + pdf}>{"Example: "+topic}</a>
            </p>
                : null
        )
    }
    render() {
        const type = this.context.fileType;

        // Do we have a video and examples to show?
        let row;
        if (this.props.video) {
            row =
                <div className="row">
                    <div className="col-sm-6">
                        <T.p text={{key: type + ".blurb"}}/>
                        <LinkingStepsLink type={type}/>
                    </div>
                    <div className="col-6 col-sm-3">
                        {this.props.video
                            ? <VideoModalButton video={this.props.video}
                                                title="Overview video" label="Overview video"
                                                caption="Overview video"/>
                            : null}
                    </div>
                    <div className="col-6 col-sm-3">
                        {this.example(this.props.sciExample,"Science")}
                        {this.example(this.props.socExample,"Social Studies")}
                    </div>
                </div>
        } else {
            // No video or examples, just text.
            row =
                <div className="row">
                    <div className="col">
                        <T.p text={{key: type + ".blurb"}}/>
                    </div>
                </div>;
        }

        return (
            <div className="popover popover-header" id="PageInfoPopover">
                <button type="button" className="popover-close close" title="Close" data-cfw-dismiss="popover">
                    <span className="fa fa-close" aria-hidden="true"/>
                </button>
                <div className="popover-title mb-0_25">About the {T.translate(type+'.title')}</div>
                <div className="popover-content pt-0">
                    {row}
                </div>
                <div className="popover-arrow"></div>
            </div>
        );
    }

    componentDidMount() {
        // Show the popover if this is the first time this user has seen this document type.
        // Once shown, indicate this fact in localStorage so it won't be shown again (on this client)
        const type = this.context.fileType;
        const key = type+'.popoverShown';
        // debugger;
        var popover = document.getElementById("PageInfoPopoverButton");  // this is null in PageInfoPopover.test
        if (window.localStorage && window.localStorage[key] !== 'yes' && null != popover && popover.CFW_Popover) {
            popover.CFW_Popover('show');
            window.localStorage.setItem(key, 'yes');
        }
    }
}

PageInfoPopover.propTypes = {
    video: PropTypes.string,
    sciExample: PropTypes.string,
    socExample: PropTypes.string
};

PageInfoPopover.contextTypes = {
    fileType: PropTypes.string
};
