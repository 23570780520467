import React from 'react';
import PropTypes from 'prop-types';
import DynamicComponent from '../DynamicComponent';
import GetInvolved from '../GetInvolved';
import MyDocuments from '../components/MyDocuments';
import NewDocument from '../components/NewDocument';

/**
 * Displays a choice of the different CER types that are supported by the application.
 * When the user selects one, a new document of that type is created.
 */
export default class OrganizerChoice extends DynamicComponent {

    constructor() {
        super();

        this.createDoc = this.createDoc.bind(this)
    }

    render() {
        return (
            <main id="main_container" className="container-fluid">
                <div className="container">
                    <GetInvolved/>

                    <MyDocuments/>

                    <NewDocument createDocMethod={this.createDoc}/>
                </div>
            </main>
        );

    }

    createDoc(type) {
        if (!this.typeChosen) {
            this.typeChosen = true;
            this.props.createDocMethod("organizer-choice", type);
        } else {
            console.log("Type already chosen; ignoring second click on a create button");
        }
        return false; // ignore href in links invoking this
    }

}

OrganizerChoice.propTypes = {
    createDocMethod: PropTypes.func.isRequired
};
