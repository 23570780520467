import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import ComponentHeader from './ComponentHeader';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import {codeMirrorMultilineConfig} from '../codeMirrorConfig';

/**
 * List of terms and definitions.
 */
export default class KeyTerms extends ListComponent {

    constructor(props) {
        super(props);
        this.identifier = "terms";
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const termList = this.getContentList();
        let termsHTML = [];
        if (termList) {
            for (let key in termList) {
                if (termList.hasOwnProperty(key)) {
                    termsHTML.push(<Term key={key}
                                         id={key}
                                         deleteMethod={this.handleDeleteItem}
                                         canDelete={this.state.canDelete}/>);
                }
            }
        }
        const addButton = this.context.canEdit ?
            <div className="row mb-1">
                <div className="col-12">
                    <button type="button" onClick={this.handleAddItem} className="btn btn-corgi btn-corgi-icon">
                        <span className="icon"><span className="fa fa-plus" aria-hidden="true"/></span>
                        Add a Key Term
                    </button>
                </div>
            </div>
            : null;
        return (
            <div>
                <ComponentHeader stepnum={this.props.stepnum} identifier={this.identifier}/>
                <div className="row" aria-hidden="true">
                    <div className="col-6">
                        <div className="h4">Word or Phrase</div>
                    </div>
                    <div className="col-6">
                        <div className="h4">Definition</div>
                    </div>
                </div>
                {termsHTML}
                {addButton}
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            term: "",
            def: ""
        })
    }
}

KeyTerms.propTypes = {
    stepnum: PropTypes.string.isRequired,
};

KeyTerms.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

/**
 *  Component for single Term-Definition pair
 */
class Term extends Component {

    constructor(props) {
        super(props);
        this.deleteModalId = uniqueId();
        this.termId = uniqueId();
        this.defId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        return (
            <div className="row row-trash" role="group" aria-label="Vocabulary item">
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor={this.termId} className="sr-only">Word or Phrase</label>
                        <div className="firepad-form form-control">
                            <div id={this.termId}/>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor={this.defId} className="sr-only">Definition</label>
                        <div className="firepad-form form-control">
                            <div id={this.defId}/>
                        </div>
                    </div>
                </div>
                <DeleteButton modalId={this.deleteModalId} tooltip="Delete Term" canDelete={this.props.canDelete}/>
                <DeleteModal id={this.deleteModalId} text="Really delete this term and definition?"
                             method={this.handleDeleteButton}/>
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/terms/" + this.props.id;
    }

    componentDidMount() {
        let termPlaceholder = T.translate(this.context.fileType + '.terms.termPlaceholder');
        let firepadTermRef = window.firebase.database().ref(this.getCurrentNode() + "/term");
        let codeMirrorTerm = window.CodeMirror(document.getElementById(this.termId),
            codeMirrorMultilineConfig(!this.context.canEdit, termPlaceholder));
        window.Firepad.fromCodeMirror(firepadTermRef, codeMirrorTerm, {
            richTextShortcuts: false,
            richTextToolbar: false
        });

        let defPlaceholder = T.translate(this.context.fileType + '.terms.defPlaceholder');
        let firepadDefRef = window.firebase.database().ref(this.getCurrentNode() + "/def");
        let codeMirrorDef = window.CodeMirror(document.getElementById(this.defId),
            codeMirrorMultilineConfig(!this.context.canEdit, defPlaceholder));
        window.Firepad.fromCodeMirror(firepadDefRef, codeMirrorDef, {
            richTextShortcuts: false,
            richTextToolbar: false
        });
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }

}

Term.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};

Term.contextTypes = {
    fileId: PropTypes.string,
    fileType: PropTypes.string,
    canEdit: PropTypes.bool
};
