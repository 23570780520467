/**
 * @fileoverview Fire store util replaces realtime util
 */

import firebase from 'firebase';

require('firebase/firestore');

const utils = {};

let firebaseConfig = window.firebaseConfig;

utils.ReatimeDbUtils = function() {
    this.init();
    window.realtimeUtils = this;
};

utils.ReatimeDbUtils.prototype = {

    init: function() {
        firebase.initializeApp(firebaseConfig);
    },

    createDoc: function(docType, id, initData) {
        return firebase.database().ref(docType+"/"+id).set(initData);
    },

    addCollaborativeUser: function(docType, docId, user) {
        return firebase.database().ref(docType+"/"+docId+"/users/"+user.uid).set(user)
    },

    removeCollaborativeUser: function(docType, docId, user) {
        return firebase.database().ref(docType+"/"+docId+"/users/"+user.uid).remove()
    },

    getCollaborativeListUser: function(docType, docId) {
        return firebase.database().ref(docType+"/"+docId+"/users")
    },

    getTitle: function(docType, docId) {
        return firebase.database().ref(docType+"/"+docId+"/title")
    },

    getDoc: function(docType, docId) {
        return firebase.database().ref(docType+"/"+docId)
    },
    
    getListDocs: function (user) {
        
    }
};

const getParam = function(urlParam) {
    let regExp = new RegExp(urlParam + '=(.*?)($|&)', 'g');
    let match = window.location.search.match(regExp);
    if (match && match.length) {
        match = match[0];
        match = match.replace(urlParam + '=', '').replace('&', '');
    } else {
        match = null;
    }
    return match;
};

export {firebaseConfig, getParam}

export default utils;
