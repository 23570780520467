import React, {Component} from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import texts from '../texts';

export default class LinkingStepsLink extends Component {

    render() {
        const list = texts[this.props.type].mnemonicSteps;
        if (!list)
            return null;

        let steps = [];
        for (let i=0; i<list.length; i++) {
            const initial = list[i].charAt(0);
            steps.push(<li key={i}><strong>{initial}</strong> – {list[i]}</li>);
        }

        return (
            <div>
                <p>
                    <a href="/" data-cfw="modal" data-cfw-modal-toggle="#linkingsteps">
                        Linking steps: {T.translate(this.props.type+".mnemonic")}
                    </a>
                </p>
                <div className="modal" id="linkingsteps">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <button type="button" className="modal-close close" data-cfw-dismiss="modal" aria-label="Close"><span className="fa fa-close" aria-hidden="true"/></button>
                            <div className="modal-body">
                                <h2>Linking Steps</h2>
                                <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                                    {steps}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

LinkingStepsLink.propTypes = {
    type: PropTypes.string.isRequired
};
