//import html2canvas from 'html2canvas';
import React, { Component } from 'react';

/*
 10/21/2019 html2canvas not working, maybe:

 npm install --save html-to-image
 https://github.com/bubkoo/html-to-image#readme

 import { toPng, toJpeg, toBlob, toPixelData, toSvgDataURL } from 'html-to-image';

 htmlToImage.toJpeg(${rootDiv}, { quality: 0.95 })
  .then(function (dataUrl) {
    var link = document.createElement('a');
    link.download = "${filename}.jpeg";
    link.href = dataUrl;
    link.click();
  })

  Unfortunately:

  "It's tested on latest Chrome and Firefox (49 and 45 respectively at the time of writing), with Chrome performing significantly better on big DOM trees, possibly due to it's more performant SVG support, and the fact that it supports CSSStyleDeclaration.cssText property.

   Internet Explorer is not (and will not be) supported, as it does not support SVG <foreignObject> tag.

   Safari is not supported, as it uses a stricter security model on <foreignObject> tag. Suggested workaround is to use toSvg and render on the server."
 */

import {toJpeg} from 'html-to-image';

class PrintButton extends Component {
  constructor(props) {
    super(props);
    this.onPrint = this.onPrint.bind(this);
  }

  onPrint() {
    let rootDiv = document.getElementById('main_container');
    // let savedPageCoords = { x: window.pageXOffset, y: window.pageYOffset };
    let filename = this.props.filename || "screenshot";
    window.scrollTo(0,0);


  toJpeg(rootDiv, { quality: 0.95, backgroundColor: 'white' })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = filename+".jpeg";
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    /*
  html2canvas(rootDiv, {
    onrendered: (canvas) => {
      window.scrollTo(savedPageCoords.x, savedPageCoords.y);
      let link = document.createElement("A");
      link.href = canvas.toDataURL();
      link.target = "_BLANK";
      link.download = `${filename}.png`;
      document.body.appendChild(link);
      link.click();
    }
  });
 */
    return false; // per MB
  }

  render() {
    return (
      <a href="#print" className={this.props.className} onClick={(evt)=> this.onPrint()}>{this.props.label}</a>
    );
  }
}

export default PrintButton;
