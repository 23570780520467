import React from 'react';
import PropTypes from 'prop-types';
import TextAreaComponent from './TextAreaComponent';
import uniqueId from '../uniqueId';

export default class SttTextAreaComponent extends TextAreaComponent {

    constructor(props) {
        super(props);
        this.wrapperClassName = "stt-wrapper";
        this.buttonId = uniqueId();
        this.buttonClassName = "stt-btn";
        this.iconClassName = "fa fa-microphone";
        this.screenReaderText = "Use Speech to Text";

        this.state = {
            listening: false
        };
        this.toggleListen = this.toggleListen.bind(this);
        this.handleListen = this.handleListen.bind(this);
        this.handleResult = this.handleResult.bind(this);
        this.handleStopped = this.handleStopped.bind(this);
    }

    renderInput() {
        return (
            this.context.recognition && this.context.recognition.isSupported() ?
                <div className={this.wrapperClassName}>
                    <div id={this.domId} className="text-area firepad-form form-control form-control-lg"/>
                    <button onClick={this.toggleListen} id={this.buttonId} type="button" className={`${this.buttonClassName} enabled ${(this.state.listening) ? 'active' : ''}`}>
                        <span className={this.iconClassName} aria-hidden="true" />
                        <span className="sr-only">{this.screenReaderText}</span>
                    </button>
                </div>
            :
                <div id={this.domId} className="text-area firepad-form form-control form-control-lg"/>
        );
    }

    toggleListen() {
        if (this.state.listening) {
            this.context.recognition.stopListening();
        } else {
            this.setState({ listening: true },
                this.handleListen);
        }
    }

    handleListen() {
        // CORGI-51 disable keyboard input with readOnly attribute
        this.codeMirror.setOption('readOnly','nocursor');
        this.currentText = this.firepad.getText();
        this.finalTranscript = '';
        this.context.recognition.startListening(this.handleResult, this.handleStopped);
    }

    handleStopped() {
        this.setState({listening: false});
        // CORGI-51 re-enable keyboard input
        this.codeMirror.setOption('readOnly',false);
    }

    handleResult(event) {
        if (!this.state.listening) return;

        let interimTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
                this.finalTranscript += event.results[i][0].transcript;
            } else {
                interimTranscript += event.results[i][0].transcript;
            }
        }

        let output = this.currentText
            + this.finalTranscript
            + interimTranscript;
        this.firepad.setText(output);
    }

}

SttTextAreaComponent.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool,
    recognition: PropTypes.object
};
