import SttTextAreaComponent from "./SttTextAreaComponent";

export default class MainIdea extends SttTextAreaComponent {

    constructor(props) {
        super(props);
        this.identifier = "mainidea";
        this.className = "jumbotron bg-blue-100 form-group";
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }
}
