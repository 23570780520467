import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DynamicComponent from './DynamicComponent';
import PrintButton from './components/PrintButton';
import BrandBar from './components/BrandBar';
import T from 'i18n-react';

export default class Header extends DynamicComponent {

    render() {
        // Minimal header for pages where no document has been loaded
        const minimal = (!this.props.authorized || !this.context.fileId || !this.props.valid);
        const type = this.context.fileType ? this.context.fileType : null;
        const title = type ? T.translate(type + ".title") : "Untitled";

        const back = true? //this.props.valid?
            <div className="col-lg header-segment">{this.classroomLink()}</div>: null;
        if (minimal) {

            return (
                <header className="print-hide">
                    <div className="row header-brand">
                        <BrandBar/>
                    </div>

                    <div className="row no-gutters header-bar">
                        <div className="col-lg header-segment">
                            <div className="header-back header-item">
                                {back}
                            </div>
                        </div>
                        <TTS minimal={minimal}/>
                    </div>
                </header>
            );

        } else {

            return (
                <header className="print-hide">
                    <div className="row header-brand">
                        <BrandBar/>
                        <div className="col text-right">
                            <a href="/" onClick={this.props.copyDocMethod} className="header-new mr-1">Make a Copy</a>
                            <a href="/" className="header-new mr-1">New Document</a>
                            {/*}
                            <PrintButton className="header-new" label="Download as Image"
                                         filename={this.props.filename}/>
                                         */}
                        </div>
                    </div>
                    <div className="row no-gutters header-bar">
                        <div className="col-lg header-segment">

                            {this.classroomLink()}
                            <h1 className="header-title">{title}</h1>
                        </div>
                        <TTS minimal={minimal}/>
                        <div className="col-md-6 col-lg-auto header-segment">
                            <Collaborators/>
                            <div className="header-item share">
                                <ShareButton shareMethod={this.props.shareMethod}/>
                            </div>
                        </div>
                    </div>
                </header>
            );
        }
    }

    classroomLink() {
        return (
            <a href="https://classroom.google.com/"
               title="Back to Google Classroom">
                <div className="sr-only-sm-down mb-0_25 small">Google Classroom</div>
                <img src="/img/google_classroom.jpg" alt="Back to Google Classroom" aria-hidden="true"/>
            </a>
        );
    }

}

Header.propTypes = {
    authorized: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    shareMethod: PropTypes.func,
    newDocMethod: PropTypes.func,
    copyDocMethod: PropTypes.func
};

Header.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string
};

/**
 * Header segment containing "More Info" button and TTS tools.
 *
 * ID Note:  The trigger button here expects the popover's ID to be #PageInfoPopover.
 * That class, in turn, expects the button's ID to be #PageInfoPopoverButton
 */
class TTS extends Component {

    constructor(props) {
        super(props);
        this.state = {clickToSpeak: false};

        this.ttsToggle = this.ttsToggle.bind(this);
    }

    render() {
        const wrapperClass = (this.props.minimal ? "col-sm col-md-auto" : "col-md-6 col-lg-auto") + " header-segment";
        return (
            <div className={wrapperClass}>
                {this.props.minimal ? null :
                    <div className="header-item">
                        <div className="sr-only-sm-down mb-0_25 small" aria-hidden="true">More Information</div>
                        <button id="PageInfoPopoverButton" type="button" className="btn btn-corgi-r4"
                                title="More Information" data-cfw="popover"
                                data-cfw-popover-toggle="#PageInfoPopover" data-cfw-popover-placement="bottom auto">
                            <span className="fa fa-info" aria-hidden="true"/>
                        </button>
                    </div>
                }
                <div className="header-item">
                    <div className="sr-only-sm-down mb-0_25 small">Read Aloud</div>
                    <div className="tts clearfix text-nowrap">
                        <button id="playButton" type="button" className="btn btn-corgi-r4" onClick={this.ttsToggle}
                                title="Toggle click-to-speak">
                            {this.state.clickToSpeak ? "ON" : "off"}
                        </button>
                    </div>
                </div>
                <div className="header-item">
                    <div className="sr-only-sm-down mb-0_25 small">Dictionary</div>
                    <button type="button" className="btn btn-corgi-r4" onClick={this.ttsDictionary}
                            title="Word Definition"><span className="fa fa-book" aria-hidden="true"/></button>
                </div>
                <div className="header-item">
                    <div className="sr-only-sm-down mb-0_25 small">Translate</div>
                    <button type="button" className="btn btn-corgi-r4" onClick={this.ttsTranslate}
                            title="Translate Word"><span className="fa fa-globe" aria-hidden="true"/></button>
                </div>
            </div>
        );
    }

    ttsToggle() {
        const setting = !this.state.clickToSpeak;
        window.$rw_enableClickToSpeak(setting);
        window.tracker.trackEvent("text-help", "click-to-speak", setting);
        this.setState({clickToSpeak: setting});
    }

    ttsDictionary() {
        if (window.$rw_isTextSelectedForPlay()) {
            window.tracker.trackEvent("text-help", "dictionary", true);
            window.$rw_event_dict();
        }
        else {
            window.tracker.trackEvent("text-help", "dictionary", false);
            alert("Select a word then click this button to look it up");
        }
    }

    ttsTranslate() {
        if (window.$rw_isTextSelectedForPlay()) {
            window.tracker.trackEvent("text-help", "translate", true);
            window.$rw_event_trans();
        }
        else {
            alert("Select a word then click this button to see the Spanish translation");
        }
    }

}


class Collaborators extends Component {

    constructor() {
        super();
        this.state = {listenerStarted: false, collaborators: null};
    }

    render() {
        if (this.context.fileId && this.state.collaborators) {
            const collaborators = this.state.collaborators;
            let icons = [], extraItems = [];
            let i = 0;
            for (let key in collaborators) {
                if (collaborators.hasOwnProperty(key)) {
                    const c = collaborators[key];
                    // First three collaborators get their icons directly in header.  Additional ones are in pulldown.
                    if (i < 3)
                        icons.push(<CollaboratorIcon key={c.uid} collab={c} wrap={false}/>);
                    else
                        extraItems.push(<CollaboratorIcon key={c.uid} collab={c} wrap={true}/>);
                    i++;
                }
            }
            const nExtra = extraItems.length;
            return (
                <div className="header-item collab">
                    <div className="sr-only-sm-down mb-0_25 small">Collaborators</div>
                    {icons}
                    {nExtra ?
                        <button type="button" className="btn btn-link plus"
                                data-cfw="popover" data-cfw-popover-toggle="#popoverCollab"
                                data-cfw-popover-placement="bottom auto" title="Show Current Collaborators">
                            +{nExtra}
                            <span className="sr-only">more collaborators</span>
                        </button>
                        : null}
                    {nExtra ?
                        <div className="popover popover-info" id="popoverCollab">
                            <button type="button" className="popover-close close" title="Close"
                                    data-cfw-dismiss="popover">
                                <span className="fa fa-close" aria-hidden="true"/>
                            </button>
                            <div className="popover-title mb-0_25">Current Collaborators</div>
                            <div className="popover-content pt-0">
                                <ul className="list-unstyled collab-list">
                                    {extraItems}
                                </ul>
                            </div>
                            <div className="popover-arrow"></div>
                        </div>
                        : null}
                </div>
            );
        } else {
            return null;
        }
    }

    componentDidMount() {
        let collaborativeListUsersRef = window.realtimeUtils.getCollaborativeListUser(this.context.fileType, this.context.fileId)
        collaborativeListUsersRef.on("value", data => {
            this.setState({
                collaborators: data.val()
            })
        });
    }
}

Collaborators.contextTypes = {
    fileId: PropTypes.string,
    fileType: PropTypes.string,
    currentUser: PropTypes.object
};

// Display for one collaborator.
// If wrap=false, returns just an avatar image.  If true, returns an <li> with image and name.
class CollaboratorIcon extends Component {

    render() {
        if (this.props.wrap) {
            return (
                <li>
                    <img className="collab-item" alt="" src={this.props.collab.photoURL}/>
                    {this.props.collab.displayName}
                </li>
            );
        } else {
            return (
                <img className="collab-item" title={this.props.collab.displayName} alt={this.props.collab.displayName}
                     src={this.props.collab.photoURL}/>
            );
        }
    }

}


class ShareButton extends Component {

    render() {
        if (!this.props.shareMethod)
            return null;

        return (
            <button className="btn btn-link text-uppercase" onClick={this.props.shareMethod}>Share</button>
        );
    }
}

CollaboratorIcon.propTypes = {
    collab: PropTypes.object.isRequired,
    wrap: PropTypes.bool.isRequired
};
