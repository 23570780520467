// This file contains text for all of the instructional hints that describe how to use the various
// fields in the Content Enhancement Routines.

const texts = {

    footer: {
        nsf: {
            url: "https://www.nsf.gov",
            grant: "This material is based upon work supported by the National Science Foundation under" +
                 " grant #1813556. Any opinions, findings, conclusions, or recommendations expressed in" +
                 " this material are those of the author(s) and do not necessarily reflect the views of" +
                 " the National Science Foundation."
        },
        texthelp: {
            thanks: "CAST would like to thank TextHelp Systems Ltd. for use of the SpeechStream toolbar."
        }
    },

    "utility" : {
        footer: "Co-Organize Your Learning (CORGI) is a google application for students and teachers to use to collaboratively answer questions requiring higher order reasoning. The CORGI guides are adapted from Strategic Instruction Model (SIM) routines developed by Janis A. Bulgren, B. Keith Lenz, Donald D. Deshler, & Jean B. Schumaker at the University of Kansas. CORGI and the adapted routines are funded by the Investing in Innovation (i3) Development Grant awarded to SRI International (#U411C14003).",
        migrationHeader: "CORGI has a new home",
        migrationBody: "The i3 Enhanced Unit project is ending and, therefore, the old CORGI site, corgi.sri.com, has been deactivated. Please bookmark the new CORGI site: "
    },

    "unit-org-2" : {

        title: "Unit Organizer",

        abbr: "UO",

        blurb: "We use the Unit Organizer to organize the critical content to be learned for each unit. We also include the unit self-test questions we are responsible for answering and the type of reasoning we will use to answer the questions. The schedule of assignments will help us see how the tasks are related to helping us answer the unit self-test questions.",

        footer: {
            sri: "Originally developed, validated and copyrighted, ‘The Unit Organizer Routine’ by B. Keith Lenz, Janis A. Bulgren, Jean B. Schumaker, Donald D. Deshler, and Daniel A. Boudah. Edge Enterprises Inc. (1994). The authors have granted their permission to SRI to adapt the Unit Organizer Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",
            nsf: "This material is based upon work supported by the National Science Foundation under\n" +
                "                                    grant #1813556. Any opinions, findings, conclusions, or recommendations expressed in\n" +
                "                                    this material are those of the author(s) and do not necessarily reflect the views of\n" +
                "                                    the National Science Foundation."
        },

        overall: {
            label: "Bigger Picture",
            help: "In this section, the name of the idea or theme that holds several units together is noted.",
            placeholder: "Type the bigger picture for the unit here."
        },

        unitTitle: {
            label: "Unit title",
            placeholder: "Enter the unit title here."
        },

        prevUnit: {
            label: "Previous Unit",
            placeholder: "Title of the previous unit."
        },

        nextUnit: {
            label: "Next Unit",
            placeholder: "Title of the next unit."
        },

        unitMap: {
            label: "Unit Map",
            help: "In this section, we show a visual way of organizing the information in the unit."
        },

        unitAbout: {
            label: "Unit Paraphrase",
            help: "In this section, a paraphrase is written that captures the main idea of the unit and distinguishes it from other units. It is written in student friendly language that is easy to understand.",
            placeholder: "Paraphrase what the unit is about."
        },

        connector: {
            placeholder: "Add connecting phrase here."
        },

        mainIdea: {
            label: "Main Ideas",
            help: "In this section, we list the main ideas or essential ideas of the unit, which is the critical content that you need to learn.",
            placeholder: "Add new main idea here."
        },

        questions: {
            label: "Self-test Questions",
            help: "In this section, we ask questions that, if answered by the end of the unit, show that we have mastered the critical content for the unit.",
            placeholder: "Type self-test question here."
        },

        orgtype: {
            label: "Unit Relationships",
            help: "The level and way of thinking that we want to use when working and interacting with the critical content.  The unit relationship determines how we are going to be working with and showing mastery of the content."
        },

        schedlist: {
            label: "Unit Schedule",
            help: "This section is a schedule of the most important activities and events in the unit (quizzes, tests, high impact activities).",
            datePlaceholder: "Date",
            assignmentPlaceholder: "Assignment"
        }

    },

    "cause-effect" : {

        title: "Cause & Effect Guide",

        abbr: "CEG",

        blurb: "We use this Cause and Effect guide to explain an event, its causes and effects.  Watch the video tutorial and click on the information icons in each section for more information on how to complete the guide.",

        footer: "Originally developed, validated and copyrighted, 'Teaching Cause and Effect' by Janis A. Bulgren, Ph.D. University of Kansas. (2013). The authors have granted their permission to SRI to adapt the Cause and Effect Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",

        mnemonic: "REASON",

        mnemonicSteps: [
            "Restate the question",
            "Examine the key terms",
            "Analyze critical event & background information",
            "Specify causes and connections",
            "Organize effects and connections",
            "Nail down the answer"
        ],

        question: {
            label: "Restated Question",
            help: "In this box, we can restate a complex or unclear question into something more simple and direct.",
            placeholder: "Restate the question."
        },

        terms: {
            label: "Key Terms",
            help: "In this box, we will define key terms that we need to know in order to complete the cause and effect guide.  We can create our own definition either using background knowledge and context in the article, or we can search in a dictionary for a definition that makes sense.  We usually need to define at least 3-5 key terms to fully understand a topic.  We can add key terms and definitions at any point as we develop the graphic.",
            termPlaceholder: "Examine the key terms.",
            defPlaceholder: "Enter the definition here."
        },

        event: {
            label: "Event and Background Information",
            help: "It is helpful to fully understand the main event before we try to identify causes and effects, so, in this box, we can type words or phrases to describe the critical event from the restated question. Then, we can list background information that helps us understand details of the event.",
            placeholder: "Analyze critical event and background information."

        },

        cause: {
            label: "Causes and Connections",
            help: "In this box, we will include one or more short statements that describe critical causes of the event.  After we describe the causes of the event, we use connecting phrases to create connections between the causes and the event.  Examples include: which led to, leading to, and causing. These connecting verbs will result in complete sentences that can be read across the guide, from causes to event to effects.",
            placeholder: "Specify causes and connections."
        },

        effect: {
            label: "Effects and Connections",
            help: "In box 5, we will include one or more short statements that describe critical effects of the event. After we describe the effects of the event, we use connecting phrases to create connections between the event and the effects.  Examples include: which led to, leading to, and causing. These connecting verbs will result in complete sentences that can be read across the guide, from causes to event to effects.",
            placeholder: "Organize effects and connections."
        },

        answer: {
            label: "Answer",
            help: "We are finally ready to write our answer to the original question. The answer should include the name of the event, the causes and effects, and an explanation for each. It’s an opportunity to use all the information we have gathered and put our answer in our own words.",
            placeholder: "Nail down the answer to the restated question."
        }
    },

    "question-exp" : {

        title: "Question Exploration Guide",

        abbr: "QEG",

        blurb: "This Guide helps us identify the main idea answer for a critical question and relate it to our understanding of the world.  Watch the video tutorial, review the examples, and read the information icons in each section to learn how to complete the guide",

        footer: "Originally developed, validated and copyrighted, 'The Question Exploration Routine' by Janis A. Bulgren, B. Keith Lenz, Donald D. Deshler, and Jean Schumaker. Edge Enterprises Inc. (2001). The authors have granted their permission to SRI to adapt the Question Exploration Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",

        mnemonic: "ANSWER",

        mnemonicSteps: [
            "Ask a Critical Question",
            "Note and explain key terms",
            "Search for Supporting Questions and their answers",
            "Work out a Main Idea answer to the critical question that is concise and usable",
            "Explore the Main Idea to show deeper understanding",
            "Relate the main idea to today’s world"
        ],

        question: {
            label: "Critical Question",
            help: "In this box, we will ask or review a critical question.",
            placeholder: "Ask a critical question."
        },

        terms: {
            label: "Key Terms",
            help: "In this section, we need to identify and understand the key terms so we can answer the questions.  If not already provided, type any Key Terms and use a dictionary or background knowledge to define them.",
            termPlaceholder: "Note key terms.",
            defPlaceholder: "Enter the definition of the key term here."
        },

        qanda: {
            label: "Supporting Questions and Answers",
            help: "In this section, we need to search for supporting questions and answers for the purpose of answering the Critical Question. A good starting place to create supporting questions is to ask ourselves the 'Who', 'What', 'Where', 'When', 'Why' and 'How' as it relates to the critical question, key terms and explanation of key terms.",
            questionPlaceholder: "Place supporting questions here.",
            answerPlaceholder: "Place answer to supporting questions here."
        },

        supportingQuestion: {
            placeholder: "Place supporting questions here."
        },

        supportingAnswer: {
            placeholder: "Place answer to supporting questions here."
        },

        mainidea: {
            label: "Main Idea Answer",
            help: "In this box, we will work out the main idea answer to the critical question.",
            placeholder: "Workout a main idea answer to the critical question that is concise and usable."
        },

        related: {
            label: "Uses for the Main Idea",
            help: "In this box, we will explore our main idea by applying it to a related content area in order to understand it in more depth. This can be written as a statement or as other questions.  ",
            placeholder: "Explore the main idea answer to show deeper understanding."
        },

        overall: {
            label: "Overall Idea and Real-World Use",
            help: "In this box, we’ll ask ourselves how the overall idea or main idea answer applies to our life, school, town, nation or the environment.  Then we type in a question or action that demonstrates understanding of how the overall idea or main idea answer relates to the real world application.",
            placeholder: "Relate the main idea to today’s world"
        }
    },

    "comparison" : {

        title: "Comparison Table",

        abbr: "CT",

        blurb: "We use this comparison table to describe similarities and differences across concepts.  Watch the video tutorial and click on the information icons in each section for more information on how to complete the guide.",

        footer: "Originally developed, validated and copyrighted, ‘The Concept Comparison Routine’ by Janis A. Bulgren, B. Keith Lenz, Donald D. Deshler, & Jean B. Schumaker, Edge Enterprises Inc. (1995). The authors have granted their permission to SRI to adapt the Concept Comparison Routine and display and distribute the adaptation on this site via an application hosted by Google, funded by the U.S. Department of Education, Investing in Innovation (i3) Development Grant #U411C140003.  The contents of this site were developed under the i3 grant from the Department of Education. However, those contents do not necessarily represent the policy of the Department of Education, and you should not assume endorsement by the Federal Government.",

        mnemonic: "COMPARING",

        mnemonicSteps: [
            "Communicate targeted concepts",
            "Obtain the Overall Concept",
            "Make lists of known characteristics",
            "Pin down Like Characteristics",
            "Assemble Like Categories",
            "Record Unlike Characteristics",
            "Identify Unlike Categories",
            "Nail down a summary",
            "Go beyond the basics"
        ],

        concept1: {
            label: "Concept A",
            help: "In this box, we list the concepts, or examples of a concept that are being compared.  A concept is a category or class into which events, ideas or objects can be grouped.",
            placeholder: "Communicate targeted concept A."
        },

        concept2: {
            label: "Concept B",
            help: "In this box, we list the concepts, or examples of a concept that are being compared.  A concept is a category or class into which events, ideas or objects can be grouped.",
            placeholder: "Communicate targeted concept B."
        },

        overall: {
            label: "The Overall Concept",
            help: "In this box, we write a word or phrase for the larger group within which both concepts fit, or, in other words, the overall concept.",
            placeholder: "Obtain the overall concept."

        },

        characteristics1: {
            label: "Characteristics",
            help: "Characteristics are distinguishing traits or features of a concept. Other words for characteristics include ‘qualities,’ ‘traits,’ or ‘attributes.’ In these columns we will make lists of all important characteristics of each concept.",
            placeholder: "Make lists of characteristics for concept A."
        },

        characteristics2: {
            label: "Characteristics",
            help: "Characteristics are distinguishing traits or features of a concept. Other words for characteristics include ‘qualities,’ ‘traits,’ or ‘attributes.’ In these columns we will make lists of all important characteristics of each concept.",
            placeholder: "Make lists of characteristics for concept B."
        },

        likecharacteristics: {
            label: "Like Characteristics",
            singleLabel: "Like characteristic",
            help: "In box 4, we pin down like characteristics that the concepts have in common, also known as ‘like characteristics.’ To do this, click on ‘Find Like Pair.’  Then, choose a pair of characteristics from concept A and concept B that are similar. Add the pair to this section.  Repeat until you’ve identified all the like pairs.",
            delete: "Remove from the list of like characteristics?"
        },

        likecategories: {
            label: "Like Categories",
            singleLabel: "Category",
            help: "‘Like categories’ are more general categories to which the ‘like characteristics’ belong.  In box 5, we want to broaden our understanding of the shared characteristics by assembling ‘like category’ in which the ‘like characteristic’ fits. We can make this statement: “This ‘like characteristic’ is about…. (insert ‘like category’).",
            placeholder: "Assemble like categories."
        },

        unlikecharacteristics: {
            label: "Unlike Characteristics",
            singleLabel: "Unlike characteristic of A",
            secondaryLabel: "Unlike characteristic of B",
            help: "In box 6, we record unlike characteristics between the two concepts.  First, click on ‘Find Unlike Pair.’ Then choose a characteristic from Concept A and a characteristic from Concept B that are unlike. Add the unlike pair to this section. Repeat until you’ve identified all the unlike pairs. ",
            delete: "Remove from the list of unlike characteristics?"
        },

        unlikecategories: {
            label: "Unlike Categories",
            singleLabel: "Category",
            help: "‘Unlike categories’ are more general categories to which the ‘unlike characteristics’ belong.  In box 7, we identify the ‘unlike category’ in which the matched pair of ‘unlike characteristics’ fits. To identify the name of the ‘unlike category,’ we think about the ‘unlike characteristic’ and make this statement…’this pair of unlike characteristics is about…. (insert ‘unlike category)’",
            placeholder: "Identify unlike categories."
        },

        summary: {
            label: "Summary",
            help: "In section 8, we nail down a summary about how the concepts are alike and how they are different. The summary statement could include a sentence about the common characteristics of the two concepts, and a sentence about like categories. Then, we could include a sentence about how the concepts are different.",
            placeholder: "Nail down a summary."
        },

        extensions: {
            label: "Extensions",
            help: "Your teacher will add an extension so that we can use our understandings in new situations.",
            placeholder: "Go beyond the basics.  Your teacher will add an extension here."
        }
    }
};

export default texts;
