import React from 'react';
import OrganizerComponent from './OrganizerComponent';
import PageInfoPopover from '../components/PageInfoPopover';
import GuideTitle from '../components/GuideTitle';
import Overall from '../components/Overall';
import CharacteristicsWrapper from '../components/CharacteristicsWrapper';
import Summary from '../components/Summary';
import Extensions from '../components/Extensions';

export default class Comparison extends OrganizerComponent {

    render() {
        return (
            <main id="main_container" className="container-fluid">
                <PageInfoPopover video="comparison-tour"
                                 socExample="CT_Soc.pdf"
                                 sciExample="CT_Sci.pdf"
                />
                <GuideTitle label="Comparison Title" placeholder="Untitled Comparison Table"/>

                <div className="row gridline gridline-hr pt-1">
                    <div className="col">
                        <Overall stepnum="2"/>
                    </div>
                </div>

                <CharacteristicsWrapper/>

                <div className="row gridline gridline-hr pt-1">
                    <div className="col">
                        <Summary stepnum="8"/>
                    </div>
                </div>

                <div className="row gridline gridline-hr pt-1">
                    <div className="col">
                        <Extensions stepnum="9"/>
                    </div>
                </div>

            </main>
        );
    }

}
