import InputComponent from "./InputComponent";

export default class NextUnit extends InputComponent {

    constructor() {
        super();
        this.identifier = "nextUnit";
    }

}
