import React, {Component} from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';

import {track_config} from './tracker';
import texts from './texts';
import './ui';
import Header from './Header';
import Footer from './Footer';
import DocTypeChoice from './organizers/OrganizerChoice';
import CauseEffect from './organizers/CauseEffect';
import QuestionExp from './organizers/QuestionExp';
import Comparison from './organizers/Comparison';
import UnitOrganizer from './organizers/UnitOrganizer';
import Unsupported from './organizers/Unsupported';
import DynamicComponent from './DynamicComponent';
import InvalidIdError from './errors/InvalidIdError.js';
import corgiDocIcon from './corgi-doc-icon.base64.js';
import Migration from './Migration';
import UserSupport from './components/UserSupport';
import utils, {firebaseConfig, getParam} from './realtimedb-utils';
import firebase from 'firebase/app';
import 'firebase/auth';
import STT from "./STT";
import {BrowserRouter, Route, Switch} from "react-router-dom";

require("firebase/auth");
let _paq = [];
window._paq = _paq;

class CERTrack {
    constructor(config) {
        // tracker methods like "setCustomDimension" should be called before "trackPageView"
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
        // accurately measure the time spent on the last pageview of a visit
        window._paq.push(['enableHeartBeatTimer']);
        ((u, id) => {
            window._paq.push(['setTrackerUrl', `${u}piwik.php`]);
            window._paq.push(['setSiteId', `${id}`]);
            let d = window.document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript';
            g.async = true;
            g.defer = true;
            g.src = `${u}piwik.js`;
            s.parentNode.insertBefore(g, s);
        })(config.url, config.siteId);
        this.trackAuthorizedUser = this.trackAuthorizedUser.bind(this);
        this.trackEvent = this.trackEvent.bind(this);
        this.trackPageView = this.trackPageView.bind(this);
    }


    trackAuthorizedUser(pageTitle, user) {
        console.log("trackAuthorization", user);
        window._paq.push(['setDocumentTitle', pageTitle]);
        // CORGI-44 Implement anonymous identifiers for CORGI 1
        //window._paq.push(['setUserId', `${user.displayName} <${user.emailAddress}>`]);

        window._paq.push(['trackPageView']);
    }

    trackEvent(action, name, value) {
        window._paq.push(['trackEvent', action, name, value]);
    }

    trackPageView(pageTitle) {
        window._paq.push(['setDocumentTitle', pageTitle]);
        window._paq.push(['trackPageView']);
    }
}

export default class App extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,      // True until everything is loaded and ready for user interaction
            migrationMessage: false, // True if we're showing a message to users redirected from old site.
            authorized: false,  // True after successful authorization with Google APIs
            copyPending: null,  // Normally null; set to new filename during a copy operation
            fileIdLoaded: false,
            fileId: null,       // ID of collaborative document
            fileType: null,     // Type of CER
            fileName: null,
            fileTypeValid: false, // Whether type is actually valid
            metadata: null,     // Google Drive metadata
            currentUser: null,
            error: 0
        };
        T.setTexts(texts);
        if (process.env.NODE_ENV !== "production") {
            window.tracker = new CERTrack(track_config.development);
        } else {
            window.tracker = new CERTrack(track_config[process.env.NODE_ENV]);
        }
        //Init realtime db connection and relative api
        new utils.ReatimeDbUtils();

        // Set up speech to text
        this.recognition = new STT();

        // Binding is required in order for methods of the class to have access to "this".
        this.authorize = this.authorize.bind(this);
        this.authorizeWithPopUp = this.authorizeWithPopUp.bind(this);
        this.afterAuthorized = this.afterAuthorized.bind(this);
        this.loadGoogleClientApiAndAuthorize = this.loadGoogleClientApiAndAuthorize.bind(this);
        this.loadFile = this.loadFile.bind(this);
        this.onFileLoaded = this.onFileLoaded.bind(this);
        this.closeFile = this.closeFile.bind(this);
        this.getMetadata = this.getMetadata.bind(this);
        this.createDoc = this.createDoc.bind(this);
        this.createDriveFile = this.createDriveFile.bind(this);
        this.updateDriveFile = this.updateDriveFile.bind(this);
        this.createRealtimeFile = this.createRealtimeFile.bind(this);
        this.shareFile = this.shareFile.bind(this);
        this.handleCopyButton = this.handleCopyButton.bind(this);
        this.copyFile = this.copyFile.bind(this);
        window.firebase = firebase;
    }


    getChildContext() {
        let canEdit = this.state.metadata ? this.state.metadata.capabilities.canEdit : false;
        return {
            fileId: this.state.fileId,
            fileType: this.state.fileType,
            canEdit: canEdit,
            fileName: this.state.fileName,
            currentUser: this.state.currentUser,
            recognition: this.recognition
        }
    }

    render() {
        const shareMethod = (this.state.metadata && this.state.metadata.capabilities.canShare) ? this.shareFile : null;

        return (
            <BrowserRouter>
                <Header authorized={this.state.authorized}
                        valid={this.state.fileTypeValid}
                        shareMethod={shareMethod}
                        newDocMethod={this.closeFile}
                        copyDocMethod={this.handleCopyButton}
                        filename={this.state.metadata ? this.state.fileName : null}
                />
<Switch>
                <Route path="/#migration" component={Migration} />
                <Route path="/support" component={UserSupport} />
                <Route path="/" render={() => {
                    if (this.state.loading) {
                        return (<Loading/>);
                    } else if (this.state.copyPending) {
                        return (<Copying/>);
                    } else if (!this.state.authorized) {
                        // CORGI-104 show new pre-login / authorize page
                        return (<PreLogin authorizeMethod={this.authorizeWithPopUp}/>);
                    } else if (this.state.fileId == null && this.state.fileIdLoaded) {
                        return (<DocTypeChoice createDocMethod={this.createDoc}/>);
                    } else if (this.state.fileIdLoaded) {
                        return (<MainContent authorized={this.state.authorized}
                                             valid={this.state.fileTypeValid}
                        />);
                    } else if(this.state.error !== 0) {
                        return (<InvalidIdError/>);
                    }
                }} />
</Switch>

                {this.state.loading ? null :
                    <Footer valid={this.state.fileTypeValid}/>}

            </BrowserRouter>
        );
    }

    // realtimeUtils is deprecated TODO: replace with

    componentDidMount() {
        // Try a quick, no-popup authorization for the case where user is already logged in.
        // If this fails, they will have to click the "Authenticate" button to launch the pop-up.
        this.authorize();
        // Remove user from collaborative user list when close window
        window.addEventListener("beforeunload", (e) => {
            window.realtimeUtils.removeCollaborativeUser(this.state.fileType, this.state.fileId, this.state.currentUser)
        });
        // Load TextHelp toolbar
        window.TexthelpSpeechStream.addToolbar();
    }

    componentWillUnmount() {
        window.realtimeUtils.getTitle(this.state.fileType, this.state.fileId).off();
    }

    listenToFileName() {
        let titleRef = window.realtimeUtils.getTitle(this.state.fileType, this.state.fileId);
        titleRef.on("value", data => {
            let titleHeadless = new window.Firepad.Headless(titleRef);
            titleHeadless.getText(text => {
                if (text !== this.state.fileName) {
                    this.setState({
                        fileName: text
                    })
                }
                titleHeadless.dispose();
            });
        });
    }

    // Attempt to auto authorize with Google Firebase.
    authorize() {
        this.loadGoogleClientApiAndAuthorize().then(() => {
            this.authorizeFirebase();
        })
    }

    loadGoogleClientApiAndAuthorize() {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://apis.google.com/js/api.js";
            script.onload = (e) => {
                window.gapi.load("client:auth2", () => {
                    window.gapi.client.init(
                        {
                            clientId: firebaseConfig.clientId,
                            scope: firebaseConfig.scopes.join(" "),
                        }
                    ).then(() => {
                        if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
                            resolve()
                        } else {
                            this.setState({loading: false});
                        }
                    });
                });
                window.gapi.load("drive-share", () => {
                    console.log("drive loaded")
                }, error => console.log("can not load drive", error))
            };
            document.body.appendChild(script);
        })
    }

    // Authorize firebase
    authorizeFirebase() {
        let googleUser = window.gapi.auth2.getAuthInstance().currentUser.get();
        let isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
        if (isSignedIn) {
            let idToken = googleUser.getAuthResponse().id_token;
            let creds = firebase.auth.GoogleAuthProvider.credential(idToken);
            firebase.auth().signInAndRetrieveDataWithCredential(creds).then((userCre) => {
                let user = userCre.user;
                if (user) {
                    let currentUser = {
                        uid: user.uid,
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        emailAddress: user.email
                    };
                    this.afterAuthorized(currentUser);
                }
            })
        } else {
            console.log("auto authorize did not work, redirect to pre-login")
        }
    }

    afterAuthorized(currentUser) {
        this.setState({authorized: true, loading: false, currentUser: currentUser}, () => {
            // Load a file, or create one if there is not an id in the URL.
            const id = getParam('id');
            const type = getParam('type');
            if (id) {
                this.loadFile(id);
            } else if (type) {
                if (this.isFileTypeValid(type)) {
                    console.log('Creating new file of type: ' + type);
                    this.createDoc('unit-org', type);
                } else {
                    console.log("Invalid type in query args");
                }
            } else {
                // No document to load - choice will be presented.
                window.tracker.trackAuthorizedUser("create-new-doc", this.state.currentUser);
                this.setState({fileIdLoaded: true, loading: false});
            }
        });
    }

    // Login with google
    authorizeWithPopUp() {
        window.gapi.auth2.getAuthInstance().signIn().then(() => {
            this.authorizeFirebase();
        }, error => console.log("Error while logging in", error));
    };

    // Retrieve the file with the given ID from Google Drive.
    loadFile(id) {
        this.getMetadata(id).then(metadata => {
            this.onFileLoaded(id, metadata)
        });
    }

    // Called when we have connected to a file.
    // and set state variables which will connect the data model to the UI.
    onFileLoaded(id, metadata) {
        this.setState({
            fileIdLoaded: true,
            fileId: id,
            fileType: metadata.properties.type,
            metadata: metadata,
            fileTypeValid: this.isFileTypeValid(metadata.properties.type),
            loading: false,
            copyPending: null
        }, () => this.listenToFileName());
        window.realtimeUtils.addCollaborativeUser(metadata.properties.type, id, this.state.currentUser);
        window.tracker.trackAuthorizedUser(`loaded-${metadata.properties.type}`, this.state.currentUser);
    }

    // Creates a new file and attaches to it a new realtime document of the given type.
    createDoc(page, type) {
        const title = "Untitled " + T.translate(type + ".title");
        this.createDriveFile(title, type).then(id => {
            this.createRealtimeFile(id, title, type)
        }).catch(error => {
            console.log("Error while creating drive file", error);
        });
        window.tracker.trackEvent(page, "create-new", type);
    }

    createRealtimeFile(id, title, type) {
        window.realtimeUtils.createDoc(type, id, {type: type}).then(() => {
            let firepadRef = window.realtimeUtils.getTitle(type, id);
            let headlessFirepad = new window.Firepad.Headless(firepadRef);
            headlessFirepad.setText(title, () => {
                headlessFirepad.dispose();
                this.loadFile(id);
            });
        }).catch(error => {
            console.log("Error while creating realtime file", error);
        });
    }

    updateDriveFile(changes) {
        return new Promise((resolve, reject) => {
            window.gapi.client.request(
                {
                    path: "https://www.googleapis.com/drive/v3/files/" + this.state.fileId,
                    method: "PATCH",
                    body: changes
                }
            ).then(() => {
                resolve()
            }, error => {
                reject(error)
            });
        })
    }

    // Creates a new Google Drive file and runs the given callback with the result.
    createDriveFile(title, type) {
        return new Promise((resolve, reject) => {
            window.gapi.client.request(
                {
                    path: "https://www.googleapis.com/drive/v3/files",
                    method: "POST",
                    body: {
                        mimeType: "application/vnd.google-apps.drive-sdk",
                        name: title,
                        properties: {
                            type: type
                        },
                        contentHints: {
                            thumbnail: {
                                image: corgiDocIcon,
                                mimeType: "image/png"
                            }
                        },
                        description: ("CORGI document: " + T.translate(type + ".title"))
                    }
                }
            ).then(response => {
                let fileId = response.result.id;
                window.history.pushState(null, "", '?id=' + fileId);
                resolve(fileId)
            }, error => {
                reject(error);
            });
        });
    }

    getMetadata(id) {
        return new Promise(resolve => {
            window.gapi.client.request({
                path: "https://www.googleapis.com/drive/v3/files/" + id,
                method: "GET",
                params: {
                    fields: "capabilities, properties, id, name, mimeType, kind"
                }
            }).then((response) => {
                    resolve(response.result);
                },
                (err_reason) => {
                    console.log("Could not get file metadata: " + err_reason.result.error.message);
                    this.setState({
                        error: 1
                    })
                })
        })
    }

    // Stop editing document and revert to the create-a-new-doc view.
    closeFile() {
        window.history.pushState(null, "", '?');
        window.realtimeUtils.removeCollaborativeUser(this.state.fileType, this.state.fileId, this.state.currentUser);
        this.setState({
            doc: null,
            fileId: null,
            filename: null,
            fileType: null,
            metadata: null
        });
    }

    handleCopyButton() {
        let oldName = this.state.fileName;
        const newName = window.prompt("Copy file to new name:", "Copy of " + oldName);
        if (newName === null || newName === "") {
            console.log("No new name, copy operation cancelled");
            return;
        }
        this.setState({copyPending: newName}, () => {
            this.copyFile();
        });
    }

    copyFile() {
        const newName = this.state.copyPending;
        const fileType = this.state.fileType;
        const oldName = this.state.fileName;
        this.createDriveFile(newName, fileType).then(id => {
            window.tracker.trackEvent(fileType, "copy-file", `"${oldName}" -> "${newName}"`);
            window.realtimeUtils.getDoc(fileType, this.state.fileId).once("value", data => {
                this.copyRealtimeFile(data.val(), id, newName);
            })
        });
    }

    // Copy the given Realtime document into a new Realtime doc model.
    copyRealtimeFile(docToCopy, id, title) {
        window.realtimeUtils.createDoc(docToCopy.type, id, docToCopy).then(() => {
            let firepadRef = window.realtimeUtils.getTitle(docToCopy.type, id);
            let headlessFirepad = new window.Firepad.Headless(firepadRef);
            headlessFirepad.setText(title, () => {
                headlessFirepad.dispose();
                this.loadFile(id);
            });
        }).catch(error => {
            console.log("Error while copy realtime file", error);
        });
    }

    shareFile() {
        const shareClient = new window.gapi.drive.share.ShareClient();
        shareClient.setOAuthToken(window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token);
        shareClient.setItemIds(this.state.fileId);
        shareClient.showSettingsDialog();
        window.tracker.trackEvent(this.state.fileType, "share-doc", this.state.fileName);
    }

    isFileTypeValid(type) {
        return (type === 'unit-org-2'
            || type === 'cause-effect'
            || type === 'question-exp'
            || type === 'comparison');
    }
}

App.childContextTypes = {
    fileId: PropTypes.string,
    fileType: PropTypes.string,
    fileName: PropTypes.string,
    canEdit: PropTypes.bool,
    currentUser: PropTypes.object,
    recognition: PropTypes.object
};


// Include the appropriate component for the main part of the page.

class MainContent extends Component {

    render() {
        if (!this.props.authorized) {
            return null;
        }
        const type = this.context.fileType;
        if (!this.props.valid)
            return (<Unsupported/>);
        if (type === 'unit-org-2')
            return (
                <UnitOrganizer/>);
        if (type === 'cause-effect')
            return (<CauseEffect/>);
        if (type === 'question-exp')
            return (<QuestionExp/>);
        if (type === 'comparison')
            return (<Comparison/>);
        alert("Unknown type of content enhancement routine: " + type);
        return null;
    }

}

MainContent.propTypes = {
    authorized: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

MainContent.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

class Loading extends Component {

    render() {
        return (
            <main id="main_container" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col text-center mt-2">
                            <h1 className="h4 text-body mb-2">Loading...</h1>
                        </div>
                    </div>
                </div>
            </main>
        )
    }

}

/**
 * Just presents a message saying that a copy operation is in progress.
 * It's important to have this intermediate stage since the operation can take
 * a few seconds, during which editing should not be allowed; and as a way of making
 * sure that the entire page is redrawn to remove any bound text input fields that
 * were connectd to the previous document.
 */
class Copying extends Component {

    render() {
        return (
            <main id="main_container" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col text-center mt-2">
                            <h1 className="h4 text-body mb-2">Copying document...</h1>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

// CORGI-104 pre-login
class PreLogin extends DynamicComponent {
    render() {
        return (
            <main id="main_container" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="h1-authorize">Corgi: Co-organize your learning</h1>
                        </div>

                        <div className="col-md-6 mt-md-1">
                            <div className="video-wrapper" data-cfw="player">
                                <div className="video-responsive">
                                    <video poster="/img/CORGI-video-poster.png"
                                           controls
                                    >
                                        <source src="/video/corgi-intro.mp4" type="video/mp4"/>
                                        <track src="/video/CORGI-video-captions.vtt" label="English subtitles"
                                               kind="subtitles" srcLang="en" default/>
                                        <p>HTML5 video not supported</p>
                                    </video>
                                </div>
                                <div className="player-wrapper">
                                    <div className="player" role="region" aria-label="video player"
                                         data-cfw-player="player">
                                        <div className="row no-gutters">
                            <span className="col-auto player-control" data-cfw-player="control">
                                <button type="button" className="btn player-play" data-cfw-player="play" title="Play"
                                        aria-label="Play"><span className="fa fa-fw fa-play" aria-hidden="true"></span></button>
                                <button type="button" className="btn player-pause" data-cfw-player="pause" title="Pause"
                                        aria-label="Pause"><span className="fa fa-fw fa-pause"
                                                                 aria-hidden="true"></span></button>
                                <button type="button" className="btn player-stop" data-cfw-player="stop" title="Stop"
                                        aria-label="Stop"><span className="fa fa-fw fa-stop" aria-hidden="true"></span></button>
                            </span>
                                            <span className="col player-time col-xs display-flex" data-cfw-player="time">
                                <span className="row no-gutters">
                                    <span className="col-auto player-time-current"
                                          data-cfw-player="time-current"></span>
                                    <span className="col player-seek" data-cfw-player="seek">
                                	    <label>Seek slider<input type="text"/></label>
                                    </span>
                                    <span className="col-auto player-time-duration"
                                          data-cfw-player="time-duration"></span>
                                </span>
                            </span>
                                            <span className="col-auto player-action">
                                <button type="button" className="btn player-caption" data-cfw-player="caption"
                                        title="Closed captions" aria-label="Closed captions"><span
                                    className="fa fa-fw fa-cc" aria-hidden="true"></span></button>
                                                {/*  <button type="button" class="btn player-transcript" data-cfw-player="transcript" title="Transcript" aria-label="Transcript"><span class="fa fa-fw fa-file-text-o" aria-hidden="true"></span></button> */}
                                                <span className="player-fullscreen" data-cfw-player="fullscreen">
                                    <button type="button" className="btn player-fullscreen-on" title="Exit fullscreen"
                                            aria-label="Exit fullscreen"><span className="fa fa-fw fa-arrows-alt"
                                                                               aria-hidden="true"></span></button>
                                    <button type="button" className="btn player-fullscreen-off" title="Fullscreen"
                                            aria-label="Fullscreen"><span className="fa fa-fw fa-arrows-alt"
                                                                          aria-hidden="true"></span></button>
                                </span>
                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 mt-md-1">
                            <p className="lead-authorize">
                                Corgi marks the next generation of graphic organizers, helping students co-organize their learning in a digital, Google App environment.
                            </p>
                            <p className="lead-authorize">
                                We want to see every student build higher-order thinking skills and become an expert learner.
                            </p>
                            <div className="row flex-middle">
                                <div className="col-md-6 text-md-center">
                                    {/*
                                    References:
                                    https://developers.google.com/identity/sign-in/web/sign-in
                                    https://developers.google.com/identity/branding-guidelines
                                    https://developers.google.com/identity/sign-in/web/build-button

                                    This 'button' is NOT accessible - Thanks Google :(

                                    <script src="https://apis.google.com/js/platform.js" async defer></script>
                                    <meta name="google-signin-client_id" content="YOUR_CLIENT_ID.apps.googleusercontent.com">
                                    <div class="g-signin2" data-onsuccess="onSignIn" data-longtitle="true"></div>
                                    */}

                                    <AuthButton authorizeMethod={this.props.authorizeMethod}/>

                                </div>
                                <div className="col-md-6 text-md-center mb-1">
                                    <a href="http://www.cast.org/our-work/research-development/projects/corgi-google-stem-middle-school.html" className="link-cta">Learn more<span className="fa fa-arrow-right"
                                                                                                                                                                                   aria-hidden="true"></span></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>


            )
    }
}


// Authorize button launches the google login popup
class AuthButton extends DynamicComponent {

    render() {
        return (
            <button type="button" className="btn-google mb-1" onClick={this.props.authorizeMethod}>
                <img src="/img/google-sign-in.svg" alt="" />
                    Sign in with Google
            </button>
        )
    }

}

AuthButton.propTypes = {
    authorizeMethod: PropTypes.func.isRequired
};
