import React, {Component} from 'react';
import T from 'i18n-react';
import BrandBar from "./components/BrandBar";
import Footer from "./Footer";

export default class Migration extends Component {

    render() {

        return (
            <div>
                <header className="print-hide">
                    <div className="row header-brand">
                        <BrandBar/>
                    </div>
                </header>
                <main id="content" className="container-fluid">
                    <div className="row">
                        <div className="col col-md-8 push-md-2 col-lg-6 push-lg-3">
                            <div className="redirect">
                                <h1 className="h2 text-body text-center mb-1">{T.translate("utility.migrationHeader")}</h1>
                                <p>
                                    {T.translate("utility.migrationBody")}
                                    <strong>https://corgi.cast.org</strong>.
                                </p>
                                <div className="text-center pb-1">
                                    <a href="/"
                                       className="btn btn-corgi-lg btn-corgi-c1 btn-redirect">
                                        Go to CORGI
                                        <span className="fa fa-arrow-circle-right text-white ml-0_5"
                                              aria-hidden="true"></span>
                                    </a>
                                </div>
                                <p className="small">
                                    Contact <a href="mailto:corgi@cast.org">corgi@cast.org</a> if you have any questions. Thanks!
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }

    toMainPage() {
        document.location.hash = "";
    }

}
