import T from "i18n-react";
import React from "react";
import VideoModalButton from "./VideoModalButton";


/**
 * Displays a card for a CER type supported by the application.
 * It provides:
 *   - a link to video about the CER type
 *   - a text description (blurb) of the CER type
 *   - a link to a completed example of the CER type (PDF)
 *   - a link to a newly created document of that type
 */
export default class Card extends React.Component {

    render() {
        const abbr = T.translate(this.props.type+".abbr");
        const tour = this.props.type === "question-exp"? "question-exploration-tour": this.props.type+"-tour";
        const mp4 = tour === "unit-org-2-tour"? null: tour; // "/video/"+tour+".mp4";
        const example = this.props.type === "unit-org-2"? null: "/example/"+abbr+"_Sci.pdf";
        const blurb = T.translate(this.props.type+".blurb");
        const title = T.translate(this.props.type+".title");
        return (

            <div className="card card-recent">
                {Card.videoLink(title,mp4)}
                <div className="card-block">
                    <h3><a href="/#CERs" onClick={this.props.creator}>{title}</a></h3>
                    <p className="card-text">{blurb}</p>
                    {Card.exampleLink(example)}
                </div>
            </div>
        )
    }

    static exampleLink(example) {
        return example?
            <p className="card-text">
                <a href={example} target="_blank" rel="noopener noreferrer" className="link-chevron">See preview</a>
            </p>
            : null;
    }

    static videoLink(title,mp4) {
        return mp4?
            <VideoModalButton title={title} video={mp4} caption={""} label={""}/>
            : null;
    }
}
