import React, {Component} from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import uniqueId from '../uniqueId';
import $ from 'jquery';

export default class InfoButton extends Component {

    componentWillMount() {
        this.buttonId = uniqueId();
        this.popoverId = uniqueId();
    }

    render() {
        const title = T.translate(this.props.type + '.' + this.props.identifier + '.label');

        return (
            <span>
                <button type="button" id={this.buttonId} className="btn btn-corgi-r5" title="More Information"><span
                    className="fa fa-info" aria-hidden="true"/></button>
                <div className="popover popover-info" id={this.popoverId}>
                    <button type="button" className="popover-close close" title="Close" data-cfw-dismiss="popover"><span
                        className="fa fa-close" aria-hidden="true"/></button>
                    <div className="popover-title mb-0_25">{title}</div>
                    <div className="popover-content pt-0">
                        <T.p text={{key: this.props.type + '.' + this.props.identifier + '.help'}}/>
                    </div>
                    <div className="popover-arrow"/>
                </div>
            </span>
        );
    }

    componentDidMount() {
        if ($(document).CFW_Popover) {
            $('#' + this.buttonId).CFW_Popover({
                placement: "bottom auto", title: "More Information",
                container: "body",
                toggle: "#" + this.popoverId
            });

            $(`#${this.buttonId}`).on('afterShow.cfw.popover', () => {
                window.tracker.trackEvent(this.props.type, "Info Box", `${this.props.identifier}`);
            });
        }
    }

}

InfoButton.propTypes = {
    type: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired
};
