import PairedCharacteristics from './PairedCharacteristics';

export default class UnlikeCharacteristics extends PairedCharacteristics {

    constructor(props) {
        super(props);

        this.charIdentifier = "unlikecharacteristics";
        this.catIdentifier = "unlikecategories";
        this.myStatus = "unlike";
        this.buttonText = "Find Unlike Pair";
        this.modalTitle = "Find an unlike pair";
        this.showBoth = true;
    }

}
