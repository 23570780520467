import React from "react";
import DynamicComponent from "../DynamicComponent";
import DocumentCard from "./DocumentCard";
import {firebaseConfig} from '../realtimedb-utils';
// import { subDays } from 'date-fns';

export default class MyDocuments extends DynamicComponent {

    constructor(props) {
        super(props);
        this.state = {};

        this.getDocuments = this.getDocuments.bind(this);
        this.getCards = this.getCards.bind(this);
    }

    // List the current users Google Drive CORGI app documents and runs the given callback with the result
    getDocuments(type) {

        let appId = firebaseConfig? firebaseConfig.clientId.split('-')[0]: "42"; // for OrganizerChoice test
        let m = "mimeType = 'application/vnd.google-apps.drive-sdk."+appId+"'";
        let t = "trashed = false";
        // let mod = "modifiedTime >= '"+subDays(new Date(),30).toISOString()+"'"; // check definition of recent

        return new Promise(resolve => {
            let criteria = {
                fields: "files(id,name,mimeType,properties/type,modifiedTime,trashed,description)",
                orderBy: "modifiedTime desc", // TODO: why doesn't this work
                pageSize: 3, // maxResults doesn't work
                q: t +" and "+m //, +" and "+mod,
            };
            if (type) {
                criteria.type = type;
            }

            window.gapi.client.request(
                {
                    path: "https://www.googleapis.com/drive/v3/files",
                    method: "GET",
                    params: criteria
                }).then((response) => {
                    this.setState({documents: response.result.files});
                    resolve(response.result.files);
                },
                (err_reason) => {
                    console.log("Could not get Drive Documents: " + err_reason.result.error.message);
                    this.setState({
                        error: 1
                    })
                })
        })
    }

    getCards(documents) {
        if (Array.isArray(documents)) {
            return (
                <div className="card-grid"> {
                    documents.map((doc) =>
                    <DocumentCard key={doc.id} document={doc} />
                    )
                } </div>
            )
        } else {
            console.error("documents is not array");
        }
    }

    componentWillMount() {

        this.getDocuments(null).then(docs => {
            this.setState({cards: this.getCards(this.state.documents)})
        });
    }

    render() {
        const appName = window.appName? window.appName: 'CORGI';
        const desktop = "&fd=true";

        return (
            <section id={"My-Documents"}>

                <h1 className="sr-only">My Documents</h1>

                <div className="py-1 group-hr">

                        <h2 className="text-body mb-1">My recent documents</h2>

                        {this.state.cards}

                        <div className="text-right">
                            <a href={"https://drive.google.com/drive/u/0/search?q=app:%22"+appName+"%22"+desktop}
                               className="link-chevron">See all my documents
                            </a>
                        </div>
                    </div>

            </section>
        )}
}
