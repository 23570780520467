import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uniqueId from '../uniqueId';
//import codeMirrorSingleLineConfig from '../codeMirrorConfig';

export default class GuideTitle extends Component {

    constructor(props) {
        super(props);
        this.domId = uniqueId();
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        return (
            <div className="row gridline gridline-hr flex-middle mb-1">
                <div className="col-sm-auto">
                    <h2 className="mb-sm-0"><label htmlFor="guideTitle">{this.props.label}</label></h2>
                </div>
                <div className="col-sm pl-sm-0">
                    <div className="form-group mb-0">
                        <div id={this.domId} className="firepad-form form-control form-control-xl"/>
                    </div>
                </div>
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/title";
    }

    componentDidMount() {
        let firepadRef = window.firebase.database().ref(this.getCurrentNode());
        let codeMirror = window.CodeMirror(document.getElementById(this.domId),
            {
                readOnly: !this.context.canEdit,
                extraKeys: {
                    "Enter": function (editor) {
                        editor.replaceSelection("")
                    }
                },
                scrollbarStyle: "null",
                placeholder: this.props.placeholder,
            });
        let titleFirepad = window.Firepad.fromCodeMirror(firepadRef, codeMirror, {
            richTextShortcuts: false,
            richTextToolbar: false
        });

        titleFirepad.on("ready", () => {
            let title = titleFirepad.getText();
            let pageTitle = title ? "CORGI: " + title : "CORGI";
            document.title = pageTitle;
            titleFirepad.on("synced", isSynced => {
                if (isSynced) {
                    title = titleFirepad.getText();
                    pageTitle = title ? "CORGI: " + title : "CORGI";
                    if (document.title !== pageTitle) {
                        document.title = pageTitle;
                        this.renameFile(title)
                    }
                }
            })
        })
    }

    renameFile(newName) {
        window.gapi.client.request(
            {
                path: "https://www.googleapis.com/drive/v3/files/" + this.context.fileId,
                method: "PATCH",
                body: {
                    name: newName,
                }
            }
        ).then(() => {
            console.log("successfully update drive file")
        }, error => {
            console.log(error)
        });
    }
}

GuideTitle.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
};

GuideTitle.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};
