import React from "react";

import { formatRelative, parseISO } from 'date-fns';

import DynamicComponent from "../DynamicComponent";
import T from "i18n-react";

export default class DocumentCard extends DynamicComponent {

    constructor(props) {
        super(props);

        this.edited = this.edited.bind(this);
    }

    edited(m) {
        const i = parseISO(m);

        return (
            i? <p className="card-text">You edited this {formatRelative(i, new Date())}</p>
             : ""
        )
    }

    render() {
        const t = T.translate(this.props.document.properties.type + ".title");
        const modified = this.edited(this.props.document.modifiedTime);

        if (t && this.props.document.id && this.props.document.name) {
            return (
                <div className="card card-recent">
                    <div className="card-header">{T.translate(this.props.document.properties.type + ".title")}</div>
                    <div className="card-block">
                        <h3><a href={"?id=" + this.props.document.id}>{this.props.document.name}</a></h3>
                        {modified}
                    </div>
                </div>
            );
        }

        return null;
    }
}
