import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Video from './Video';

export default class VideoModalButton extends Component {

    render() {
        const posterURL = process.env.PUBLIC_URL + "/video/" + this.props.video + "-lg" +
            ".jpg";
        return (
            <div className="card-img">
                <a href="#dummy" className="link-video" data-cfw="modal" data-cfw-modal-toggle={"#"+this.props.video} >
                    <img src={posterURL} alt="Watch video" className="img-responsive"/>
                </a>
                <span>{this.props.caption}</span>
                <div className="modal" id={this.props.video}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <button type="button" className="modal-close close" data-cfw-dismiss="modal" aria-label="Close"><span className="fa fa-close" aria-hidden="true"/></button>
                            <div className="modal-body">
                                <h2>{this.props.title}</h2>
                                <Video video={this.props.video}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

VideoModalButton.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired
};
