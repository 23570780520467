import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import ComponentHeader from './ComponentHeader';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import uniqueId from '../uniqueId';
import T from 'i18n-react';
import {codeMirrorMultilineConfig} from '../codeMirrorConfig';

export default class Schedule extends ListComponent {

    constructor(props) {
        super(props);

        this.canBeEmpty = false;
        this.identifier = "schedlist";
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const type = this.context.fileType;
        const itemList = this.getContentList();
        let itemsHTML = [];
        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)){
                    itemsHTML.push(<SchedItem key={key}
                                              id={key}
                                              parentNode={this.getCurrentNode()}
                                              deleteMethod={this.handleDeleteItem}
                                              canDelete={this.state.canDelete}/>);
                }
            }
        }
        const addButton = this.context.canEdit ?
            <div className="row mb-1">
                <div className="col-12">
                    <button type="button" onClick={this.handleAddItem} className="btn btn-corgi btn-corgi-icon">
                        <span className="icon"><span className="fa fa-plus" aria-hidden="true"/></span>
                        Add an Assignment
                    </button>
                </div>
            </div>
            : null;

        return (
            <div>
                <ComponentHeader stepnum={this.props.stepnum} type={type} identifier={this.identifier}/>
                <div className="row hide-xs-down" aria-hidden="true">
                    <div className="col-3">
                        <div className="h4">Date</div>
                    </div>
                    <div className="col-9">
                        <div className="h4">Assignment</div>
                    </div>
                </div>
                {itemsHTML}
                {addButton}
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            date: "",
            assignment: ""
        })
    }

}


/**
 *  Component for single schedule item (date + assignment pair)
 */
class SchedItem extends Component {

    constructor(props) {
        super(props);

        this.dateId = uniqueId();
        this.assignmentId = uniqueId();
        this.deleteModalId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render () {
        return (
            <div className="row row-trash" role="group" aria-label="Schedule item">
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor={this.dateId} className="sr-only-sm-up">Date</label>
                        <div className="text-area firepad-form form-control" id={this.dateId}></div>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="form-group">
                        <label htmlFor={this.assignmentId} className="sr-only-sm-up">Assignment</label>
                        <div className="text-area firepad-form form-control" id={this.assignmentId}></div>
                    </div>
                </div>
                <DeleteButton modalId={this.deleteModalId} tooltip="Delete Row" canDelete={this.props.canDelete}/>
                <DeleteModal id={this.deleteModalId} text="Really delete this schedule item?"
                             method={this.handleDeleteButton}/>
            </div>
        );
    }

    getCurrentNode() {
        return this.props.parentNode +"/"+ this.props.id;
    }

    componentDidMount() {
        const type = "unit-org-2";
        let datePlaceholder = T.translate(type+".schedlist.datePlaceholder");
        let firepadDateRef = window.firebase.database().ref(this.getCurrentNode() + "/date");
        let codeMirrorDate = window.CodeMirror(document.getElementById(this.dateId),
            codeMirrorMultilineConfig(!this.context.canEdit, datePlaceholder));
        window.Firepad.fromCodeMirror(firepadDateRef, codeMirrorDate, {
            richTextShortcuts: false,
            richTextToolbar: false
        });

        let assiPlaceholder = T.translate(type+".schedlist.assignmentPlaceholder");
        let firepadAssiRef = window.firebase.database().ref(this.getCurrentNode() + "/assignment");
        let codeMirrorAssi = window.CodeMirror(document.getElementById(this.assignmentId),
            codeMirrorMultilineConfig(!this.context.canEdit, assiPlaceholder));
        window.Firepad.fromCodeMirror(firepadAssiRef, codeMirrorAssi, {
            richTextShortcuts: false,
            richTextToolbar: false
        });
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }

}

SchedItem.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired,
    parentNode: PropTypes.string.isRequired
};

SchedItem.contextTypes = {
    fileId: PropTypes.string,
    fileType: PropTypes.string,
    canEdit: PropTypes.bool
};
