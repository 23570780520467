import SttTextAreaComponent from './SttTextAreaComponent';

export default class Question extends SttTextAreaComponent {

    constructor(props) {
        super(props);
        this.identifier = "question";
        this.className = "jumbotron bg-mustard-100 form-group";
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

}
