import React from 'react';
import PropTypes from 'prop-types';
import DynamicComponent from './DynamicComponent';
// import { Route } from "react-router-dom";

import T from 'i18n-react';


export default class Footer extends DynamicComponent {

    render() {
        const type = (this.context.fileType && this.props.valid)
            ? this.context.fileType
            : "utility";

        let support;
        if (document.location.pathname === '/support') {
            support = null;
        } else {
            support = <li className="list-item"><a href="/support" rel="noopener noreferrer" >User Support</a></li>;
        }
        return(
            <footer>
                <div className="container">
                    <T.p text={{key: type + ".footer"}} />
                </div>
                <div className="footer-site">
                    <div className="container">
                        <div className="footer-site-brand">
                            <div className="footer-site-logo">
                                <div className="footer-site-title">CORGI</div>
                                <div className="footer-site-tagline">Co-Organize Your Learning</div>
                            </div>
                        </div>
                        <div className="footer-site-row row">
                            <div className="col">
                                <div className="footer-site-header">Site Map</div>
                                <ul className="list-unstyled">
                                    <li className="list-item"><a href="/#CERs">Content Enhancement Routines</a></li>{/* https://sim.drupal.ku.edu/content-enhancement-routines ??? */}
                                    <li className="list-item"><a href="/#My-Documents" >My Recent Documents</a></li>

                                         { support /* CORGI-107 */}

                                    <li className="list-item"><a href="http://www.cast.org/our-work/research-development/projects/corgi-google-stem-middle-school.html">Research</a></li>

                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-site-header">Our Partners</div>
                                <ul className="list-unstyled">
                                    <li className="list-item"><a href="https://kucrl.ku.edu/">University of Kansas</a></li>
                                    <li className="list-item"><a href="https://www.sri.com/about/organization/education">SRI International</a></li>
                                    <li className="list-item"><a href="https://www.sccoe.org/index/Pages/">Santa Clara County Office of Education</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-site-header">Contact Us</div>
                                <ul className="list-unstyled">
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-envelope" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            <a href="mailto:corgi@cast.org">corgi@cast.org</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-site-media media">
                            <div className="media-object">
                                <a href={T.translate("footer.nsf.url")}><img src="/img/nsf.png"
                                                                   className="img-responsive"
                                                                   alt="National Science Foundation" /></a>
                            </div>
                            <div className="media-body">
                                <T.p text={{key: "footer.nsf.grant"}}/>
                            </div>
                        </div>
                        <div className= "footer-site-media media">
                            <div className="media-object">
                                <a href="https://texthelp.com"><img src="/img/texthelp.png"
                                                                    className="img-responsive" alt="Texthelp"/></a>
                            </div>
                            <div className="media-body">
                                <T.p text={{key: "footer.texthelp.thanks"}}></T.p>
                            </div>
                        </div>
                        <div className="footer-site-copy row">
                            <div className="col">
                                <ul className="list list-horizontal">
                                    <li className="list-item"><a href="privacy.html">CORGI'S Privacy policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-cast">
                    <div className="container">
                        <div className="footer-cast-brand">
                            <a href="http://www.cast.org" className="footer-cast-logo">
                                <img src="/img/cast/logo-footer.svg" alt=""/>
                                    <div className="footer-cast-title">CAST</div>
                                    <div className="footer-cast-tagline">Until learning has no limits</div>
                            </a>
                        </div>
                        <div className="footer-cast-row row">
                            <div className="col">
                                <div className="footer-cast-header">Connect with us</div>
                                Follow us:
                                <ul className="list list-horizontal">
                                    <li className="list-item"><a href="https://www.facebook.com/CASTUDL" className="footer-cast-social"><span
                                        className="fa fa-facebook-square" aria-label="Facebook"></span></a></li>
                                    <li className="list-item"><a href="https://www.instagram.com/cast_udl" className="footer-cast-social"><span
                                        className="fa fa-instagram" aria-label="Instragram"></span></a></li>
                                    <li className="list-item"><a href="https://www.twitter.com/CAST_UDL" className="footer-cast-social"><span
                                        className="fa fa-twitter-square" aria-label="Twitter"></span></a></li>
                                    <li className="list-item"><a href="https://www.youtube.com/user/UDLCAST" className="footer-cast-social"><span
                                        className="fa fa-youtube-square" aria-label="YouTube"></span></a></li>
                                    <li className="list-item"><a href="https://www.linkedin.com/company/castorg" className="footer-cast-social"><span
                                        className="fa fa-linkedin" aria-label="LinkedIn"></span></a></li>
                                    <li className="list-item"><a href="https://www.pinterest.com/cast_udl" className="footer-cast-social"><span
                                        className="fa fa-pinterest-square" aria-label="Pinterest"></span></a></li>
                                </ul>
                                <ul className="list-unstyled">
                                    <li className="list-item"><a href="http://eepurl.com/dwUba1">Subscribe to our newsletter</a></li>
                                    <li className="list-item"><a href="https://us4.campaign-archive.com/home/?u=89e11c7455f4cb757154eb608&id=7396dac1a2">Newsletter archive</a></li>
                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-cast-header">More from CAST</div>
                                <ul className="list-unstyled">
                                    <li className="list-item"><a href="http://udlguidelines.cast.org">Universal Design for Learning</a></li>
                                    <li className="list-item"><a href="http://www.castpl.org">Professional Learning</a></li>
                                    <li className="list-item"><a href="http://castpublishing.org/">CAST Professional Publishing</a></li>
                                    <li className="list-item"><a href="http://cisl.cast.org">Center on Inclusive Software for Learning
                                        (CISL)</a></li>
                                    <li className="list-item"><a href="http://aem.cast.org">National Center on Accessible Educational
                                        Materials (AEM Center)</a></li>
                                    <li className="list-item"><a href="http://udloncampus.cast.org">UDL on Campus</a></li>
                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-cast-header">Contact CAST</div>
                                <ul className="list-unstyled">
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-envelope" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            <a href="mailto:cast@cast.org">cast@cast.org</a>
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-phone" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            <a href="tel:+17812452212">(781) 245-2212</a>
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-map-marker" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            <a href="https://goo.gl/maps/4UQAd3q5b5fctzUn6">
                                                200 Harvard Mill Square<br/>
                                                Suite 210<br/>
                                                Wakefield, MA 01880
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-cast-copy row">
                            <div className="col">
                                <ul className="list list-horizontal">
                                    <li className="list-item"><a href="http://www.cast.org/about/donate.html"
                                                                 className="footer-cast-btn-donate btn">Donate</a></li>
                                    <li className="list-item"><a href="http://www.cast.org/site/accessibility.html">Accessibility
                                        policy</a></li>
                                    {/* <li className="list-item"><a href="http://www.cast.org/site/privacy.html">Privacy
                                        policy</a></li> // remove CAST privacy policy, since we have CORGI one - per Mindy?*/}
                                    <li className="list-item"><a href="http://www.cast.org/site/terms-of-use.html">Terms of Use</a></li>
                                </ul>
                            </div>
                            <div className="col">
                                &copy; CAST, Inc. 2019
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        );
    }

}

Footer.propTypes = {
    doc : PropTypes.object,
    valid: PropTypes.bool.isRequired
};

Footer.contextTypes = {
    fileType: PropTypes.string,
};
