import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from "./VideoPlayer";

export default class Video extends Component {
    constructor() {
        super();


        this.onPlay = this.onPlay.bind(this);
    }

    render() {

        const videoURL = process.env.PUBLIC_URL + "/video/" + this.props.video + ".mp4";
        const ccURL     = videoURL.replace("mp4", "vtt");
        const posterURL = videoURL.replace("mp4", "jpg");
        return (
            <VideoPlayer posterURL={posterURL} ccURL={ccURL} videoURL={videoURL} />
        );
    }

    onPlay (event) {
        window.tracker.trackEvent("video-player", "play", this.props.video);
    }
}


Video.propTypes = {
    video: PropTypes.string.isRequired
};
