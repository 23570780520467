import React from 'react';
import PropTypes from 'prop-types';

import TextAreaComponent from './TextAreaComponent';
import {codeMirrorSingleLineConfig} from '../codeMirrorConfig';

// An editable labeled arrow

export default class Arrow extends TextAreaComponent {

    constructor(props) {
        super(props);
        this.identifier = props.identifier;
        this.className = "col-md-1_5 col-arrow";
        if (props.className) {
            this.className = props.className;
        }
        this.placeholder = null;
        if (props.placeholder) {
            this.placeholder = props.placeholder;
        }
    }

    render() {
        return (
            <div className={this.className}>
                <label htmlFor={this.domId} className="sr-only">{this.props.label}</label>
                <div className="form-arrow">
                    <div className="form-control">
                        <div id={this.domId}
                             placeholder={this.placeholder}/>
                    </div>
                </div>
            </div>
        );
    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.props.identifier;
    }

    renderHeader() {
        return null;
    }

    componentDidMount(){
        let firepadRef = window.firebase.database().ref(this.getCurrentNode());
        let codeMirror = window.CodeMirror(document.getElementById(this.domId),
            codeMirrorSingleLineConfig(!this.context.canEdit, this.props.placeholder));
        window.Firepad.fromCodeMirror(firepadRef, codeMirror, {
            richTextShortcuts: false,
            richTextToolbar: false
        });
    }

}

Arrow.propTypes = {
    parentNode: PropTypes.string.isRequired,
    identifier : PropTypes.string.isRequired,
    default: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    className: PropTypes.string
};
