import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class VideoPlayer extends Component {
    constructor() {
        super();

        this.onPlay = this.onPlay.bind(this);
    }

    render() {
        return (
            <div data-cfw="player" className="video-wrapper">
                <div className="video-responsive">
                    <video onPlay={this.onPlay} poster={this.props.posterURL} controls>
                        <source src={this.props.videoURL} type="video/mp4" />
                        if (ccURL) {
                            <track src={this.props.ccURL} label="Subtitles" kind="subtitles" srcLang="en" default />
                        }
                        <p>HTML5 video not supported</p>
                    </video>
                </div>
                <div className="player-wrapper">
                    <div className="player"  role="region" aria-label="video player" data-cfw-player="player">
                        <div className="row no-gutters">
                            <span className="col-auto player-control" data-cfw-player="control">
                                <button type="button" className="btn player-play" data-cfw-player="play" title="Play" aria-label="Play"><span className="fa fa-fw fa-play" aria-hidden="true"></span></button>
                                <button type="button" className="btn player-pause" data-cfw-player="pause" title="Pause" aria-label="Pause"><span className="fa fa-fw fa-pause" aria-hidden="true"></span></button>
                                <button type="button" className="btn player-stop" data-cfw-player="stop" title="Stop" aria-label="Stop"><span className="fa fa-fw fa-stop" aria-hidden="true"></span></button>
                            </span>
                            <span className="col player-time col-xs display-flex" data-cfw-player="time">
                                <span className="row no-gutters">
                                    <span className="col-auto player-time-current" data-cfw-player="time-current"></span>
                                    <span className="col player-seek" data-cfw-player="seek">
                                        <label>Seek slider<input type="text" /></label>
                                    </span>
                                    <span className="col-auto player-time-duration" data-cfw-player="time-duration"></span>
                                </span>
                            </span>
                            <span className="col-auto player-action">
                                <button type="button" className="btn player-caption" data-cfw-player="caption" title="Closed captions" aria-label="Closed captions"><span className="fa fa-fw fa-cc" aria-hidden="true"></span></button>
                                <span className="player-fullscreen" data-cfw-player="fullscreen">
                                    <button type="button" className="btn player-fullscreen-on" title="Exit fullscreen" aria-label="Exit fullscreen"><span className="fa fa-fw fa-arrows-alt" aria-hidden="true"></span></button>
                                    <button type="button" className="btn player-fullscreen-off" title="Fullscreen" aria-label="Fullscreen"><span className="fa fa-fw fa-arrows-alt" aria-hidden="true"></span></button>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onPlay (event) {
        window.tracker.trackEvent("video-player", "play", this.props.video);
    }
}


VideoPlayer.propTypes = {
    videoURL: PropTypes.string.isRequired,
    posterURL: PropTypes.string.isRequired,
    ccURL: PropTypes.string.isRequired
};
