import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DeleteModal extends Component {

    constructor(props) {
        super(props);
        this.yes = (this.props.yes ? this.props.yes : "Yes, Delete");
    }

    render() {
        return (
            <div id={this.props.id} className="modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-center">
                                <h2 className="mb-2">{this.props.text}</h2>
                                <button type="button" onClick={this.props.method} data-cfw-dismiss="modal"
                                        className="btn btn-corgi-lg btn-corgi-c1 mr-0_5">{this.yes}</button>
                                <button type="button" data-cfw-dismiss="modal"
                                        className="btn btn-corgi-lg ml-0_5">No, Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

DeleteModal.propTypes = {
    id: PropTypes.string.isRequired,
    text : PropTypes.string.isRequired,
    yes : PropTypes.string,
    method: PropTypes.func.isRequired
};
