import PropTypes from 'prop-types';
import DynamicComponent from '../DynamicComponent';

/**
 * (Abstract) component that holds an extendable list of some sort.
 * Used for lists of terms and definitions, questions and answers, etc.
 *
 * Subclasses must implement render(), newItem(model), and set this.identifier .
 */
export default class ListComponent extends DynamicComponent {

    constructor(props) {
        super(props);
        this.canBeEmpty = true; // Set to false if at least one list element is required.
        this.state = {
            canDelete: false,
            items: {}   // List of item
        };

        this.handleAddItem = this.handleAddItem.bind(this);
        this.getContentList = this.getContentList.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        this.checkDeleteLegality = this.checkDeleteLegality.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        let listItemRefs = window.firebase.database().ref(this.getCurrentNode());
        listItemRefs.on("child_added", data => {
            let currentItems = this.state.items;
            currentItems[data.key] = data.val();
            this.setState({
                items: currentItems
            })
        });
        listItemRefs.on("child_removed", data => {
            let currentItems = this.state.items
            delete currentItems[data.key];
            this.setState({
                items: currentItems
            })
        });
        listItemRefs.once("value").then(list => {
            if (!this.canBeEmpty){
                if(!list.exists() || Object.keys(list.val()).length <= 0)
                    this.handleAddItem();
            }
            this.setState({canDelete: this.checkDeleteLegality(list.val())});
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (Object.keys(this.state.items).length !== Object.keys(prevState.items).length) {
            this.setState({
                canDelete: this.checkDeleteLegality(this.state.items)
            })
        }
    }

    // Create a new item add add it to the list
    handleAddItem() {
        if (!this.context.canEdit)
            return;
        this.newItem();
    }

    handleDeleteItem(id) {
        if (!this.state.canDelete) {
            console.log("Cannot delete, would make list empty");
            return;
        }
        window.firebase.database().ref(this.getCurrentNode() + "/" + id).remove();
    }

    // Determine whether delete buttons should be active; return as a boolean value.
    checkDeleteLegality(newItems) {
        if (!this.context.canEdit || !newItems)
            return false;
        if (this.canBeEmpty)
            return true;
        return (Object.keys(newItems).length > 1);
    }

    // Get content list and listen to change
    getContentList() {
        return this.state.items;
    }
}

ListComponent.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};
