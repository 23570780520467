import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import ComponentHeader from './ComponentHeader';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import uniqueId from '../uniqueId';
import SupportingQuestion from './SupportingQuestion';
import SupportingAnswer from './SupportingAnswer';

/**
 * List of questions and answers.
 */
export default class Qanda extends ListComponent {

    constructor(props) {
        super(props);
        this.canBeEmpty = false;
        this.identifier = "qanda";
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const itemList = this.getContentList();
        let itemsHTML = [];
        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)) {
                    itemsHTML.push(<QA key={key}
                                       id={key}
                                       deleteMethod={this.handleDeleteItem}
                                       canDelete={this.state.canDelete}/>);
                }
            }
        }
        const addButton = this.context.canEdit ?
            <div className="row mb-1">
                <div className="col-12">
                    <button type="button" onClick={this.handleAddItem} className="btn btn-corgi btn-corgi-icon">
                        <span className="icon"><span className="fa fa-plus" aria-hidden="true"/></span>
                        Add a New Question
                    </button>
                </div>
            </div>
            : null;

        return (
            <div>
                <ComponentHeader stepnum={this.props.stepnum} identifier={this.identifier}/>
                <div className="row hide-xs-down" aria-hidden="true">
                    <div className="col-4">
                        <div className="h4">Supporting Questions</div>
                    </div>
                    <div className="col-8">
                        <div className="h4">Answers</div>
                    </div>
                </div>
                {itemsHTML}
                {addButton}
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            q: "",
            a: ""
        })
    }

}

Qanda.propTypes = {
    stepnum: PropTypes.string.isRequired,
};

Qanda.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

/**
 *  Component for single Question-Answer pair
 */
class QA extends Component {

    constructor(props) {
        super(props);
        this.questionId = uniqueId();
        this.answerId = uniqueId();
        this.deleteModalId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        return (
            <div className="row row-trash" role="group" aria-label="Question and answer">
                <div className="col-sm-4">
                    <div className="form-group">
                        <SupportingQuestion id={this.questionId} ></SupportingQuestion>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="form-group">
                        <SupportingAnswer id={this.answerId} ></SupportingAnswer>
                    </div>
                </div>
                <DeleteButton modalId={this.deleteModalId} tooltip="Delete Row" canDelete={this.props.canDelete}/>
                <DeleteModal id={this.deleteModalId} text="Really delete this question and answer?"
                             method={this.handleDeleteButton}/>
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/quanda/" + this.props.id;
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }

}

QA.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};

