import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListComponent from './ListComponent';
import Cause from './Cause';
import Event from './Event';
import Effect from './Effect';
import Arrow from './Arrow';
import InfoButton from '../components/InfoButton';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import uniqueId from '../uniqueId';
import T from 'i18n-react';

/**
 * List of cause-event-efect chains.
 */
export default class EventChains extends ListComponent {

    constructor(props) {
        super(props);
        this.canBeEmpty = false;
        this.identifier = "chains";
        this.newItem = this.newItem.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    render() {
        const itemList = this.getContentList();
        let itemsHTML = [];
        if (itemList) {
            for (let key in itemList) {
                if (itemList.hasOwnProperty(key)) {
                    itemsHTML.push(<EventChain key={key} id={key}
                                               canEdit={this.context.canEdit}
                                               deleteMethod={this.handleDeleteItem} canDelete={this.state.canDelete}/>);
                }
            }
        }
        const addButton = this.context.canEdit ?
            <div className="row pb-1 border-t-0">
                <div className="col-12">
                    <button type="button" onClick={this.handleAddItem} className="btn btn-corgi btn-corgi-icon">
                        <span className="icon"><span className="fa fa-plus" aria-hidden="true"/></span>
                        Add Another Row
                    </button>
                </div>
            </div>
            : null;

        return (
            <div className="gridline-hr">
                <EventChainsHeaders/>
                <div className="row gridline">
                    {itemsHTML}
                </div>
                {addButton}
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    newItem() {
        window.firebase.database().ref(this.getCurrentNode()).push().set({
            cause: "",
            arrow_cause: "",
            event: "",
            arrow_effect: "",
            effect: ""
        })
    }

}

EventChains.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

/**
 *  Component for single Cause - Event - Effect row
 */
class EventChain extends Component {

    constructor(props) {
        super(props);
        this.deleteModalId = uniqueId();
        this.handleDeleteButton = this.handleDeleteButton.bind(this);
    }

    render() {
        return (
            <div className="col-12 group-hr" role="group" aria-label="Causes, Events, and Effects item">
                <div className="row row-trash">
                    <div className="col-md-3">
                        <Cause parentNode={this.getCurrentNode()}/>
                    </div>
                    <Arrow identifier="arrow_cause" parentNode={this.getCurrentNode()} default="causes"
                           label="Relation between causes and events"/>
                    <div className="col-md-3">
                        <Event parentNode={this.getCurrentNode()}/>
                    </div>
                    <Arrow identifier="arrow_effect" parentNode={this.getCurrentNode()} default="leads to"
                           label="Relation between events and effects"/>
                    <div className="col-md-3">
                        <Effect parentNode={this.getCurrentNode()}/>
                    </div>
                    <DeleteButton modalId={this.deleteModalId} tooltip="Delete Row" canDelete={this.props.canDelete}/>
                </div>
                <DeleteModal id={this.deleteModalId} text="Really delete this whole cause-event-effect row?"
                             method={this.handleDeleteButton}/>
            </div>
        );
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/chains/" + this.props.id;
    }

    handleDeleteButton() {
        this.props.deleteMethod(this.props.id);
    }
}

EventChain.propTypes = {
    id: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    deleteMethod: PropTypes.func.isRequired
};

EventChain.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};

class EventChainsHeaders extends Component {

    render() {
        const type = 'cause-effect';
        return (
            <div className="row row-trash gridline gridline-hr pt-1 pb-1 pb-md-0">
                <div className="col-12">
                    <h3 className="sr-only-md-up">
                        <span className="hnum"><small>3&middot;5</small></span>
                        Causes, Events, and Effects
                    </h3>
                </div>
                <h3 className="sr-only">Causes, Events, and Effects</h3>
                <div className="col-4 col-md-3">
                    <div className="row">
                        <div className="col mb-0_5">
                            <h3 className="d-inline mb-0 hide-sm-down" aria-hidden="true"><span
                                className="hnum">4</span> {T.translate(type + '.cause.label')}</h3>
                            <InfoButton type={type} identifier="cause"/>
                            <span className="small d-inline-block valign-middle pl-0_5 hide-md-up" aria-hidden="true">More information<br/> about Causes</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-1_5 hide-sm-down"/>
                <div className="col-4 col-md-3">
                    <div className="row">
                        <div className="col mb-0_5">
                            <h3 className="d-inline mb-0 hide-sm-down" aria-hidden="true"><span
                                className="hnum">3</span> {T.translate(type + '.event.label')}</h3>
                            <InfoButton type={type} identifier="event"/>
                            <span className="small d-inline-block valign-middle pl-0_5 hide-md-up" aria-hidden="true">More information<br/> about Events</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-1_5 hide-sm-down"/>
                <div className="col-4 col-md-3">
                    <div className="row">
                        <div className="col mb-0_5">
                            <h3 className="d-inline mb-0 hide-sm-down" aria-hidden="true"><span
                                className="hnum">5</span> {T.translate(type + '.effect.label')}</h3>
                            <InfoButton type={type} identifier="effect"/>
                            <span className="small d-inline-block valign-middle pl-0_5 hide-md-up" aria-hidden="true">More information<br/> about Effects</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
