import React, {Component} from 'react';
import PropTypes from 'prop-types';

import uniqueId from '../uniqueId';
import T from "i18n-react";
import {codeMirrorMultilineConfig} from "../codeMirrorConfig";

// merged SttTextAreaComponent and TextAreaComponent with no header
export default class SttTextAreaComponent2 extends Component {

    constructor(props) {
        super(props);

        this.domId = uniqueId();
        // CSS class that will be set for the component's label
        this.labelClassName = "sr-only";
        // CSS class for the component's form group
        this.formClassName = "form-group";
;
        //this.renderHeader = this.renderHeader.bind(this);
        this.firepad = null;
        this.codeMirror = null;

        this.wrapperClassName = "stt-wrapper";
        this.buttonId = uniqueId();
        this.buttonClassName = "stt-btn";
        this.iconClassName = "fa fa-microphone";
        this.screenReaderText = "Use Speech to Text";

        this.state = {
            listening: false
        };
        this.toggleListen = this.toggleListen.bind(this);
        this.handleListen = this.handleListen.bind(this);
        this.handleResult = this.handleResult.bind(this);
        this.handleStopped = this.handleStopped.bind(this);
    }

    componentDidMount() {
        // placeholder already passed in props?
        const placeholder = T.translate(this.context.fileType + '.' + this.identifier + '.placeholder');
        let firepadRef = window.firebase.database().ref(this.getCurrentNode());
        this.codeMirror = window.CodeMirror(document.getElementById(this.domId),
            codeMirrorMultilineConfig(!this.context.canEdit, placeholder));
        this.firepad = window.Firepad.fromCodeMirror(firepadRef, this.codeMirror, {});
    }

    render() {
        const label = T.translate(this.context.fileType + '.' + this.identifier + '.label');
        return (
            <div>
                <div className={this.formClassName}>
                    <label htmlFor={this.domId} className={this.labelClassName}>{label}</label>
                    <div className="stt-wrapper">
                        {this.renderInput()}
                        <button type="button" className="stt-btn" data-cast="stt" data-target="#resq">
                            <span className="fa fa-microphone" aria-hidden="true"></span>
                            <span className="sr-only">Use Speech to Text</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderInput() {
        return (
            this.context.recognition && this.context.recognition.isSupported() ?
                <div className={this.wrapperClassName}>
                    <div id={this.domId} className="text-area firepad-form form-control form-control-lg"/>
                    <button onClick={this.toggleListen} id={this.buttonId} type="button" className={`${this.buttonClassName} enabled ${(this.state.listening) ? 'active' : ''}`}>
                        <span className={this.iconClassName} aria-hidden="true" />
                        <span className="sr-only">{this.screenReaderText}</span>
                    </button>
                </div>
            :
                <div id={this.domId} className="text-area firepad-form form-control form-control-lg"/>
        );
    }

    toggleListen() {
        if (this.state.listening) {
            this.context.recognition.stopListening();
        } else {
            this.setState({ listening: true },
                this.handleListen);
        }
    }

    handleListen() {
        // CORGI-51 disable keyboard input with readOnly attribute
        this.codeMirror.setOption('readOnly','nocursor');
        this.currentText = this.firepad.getText();
        this.finalTranscript = '';
        this.context.recognition.startListening(this.handleResult, this.handleStopped);
    }

    handleStopped() {
        this.setState({listening: false});
        // CORGI-51 re-enable keyboard input
        this.codeMirror.setOption('readOnly',false);
    }

    handleResult(event) {
        if (!this.state.listening) return;

        let interimTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
                this.finalTranscript += event.results[i][0].transcript;
            } else {
                interimTranscript += event.results[i][0].transcript;
            }
        }

        let output = this.currentText
            + this.finalTranscript
            + interimTranscript;
        this.firepad.setText(output);
    }

}

SttTextAreaComponent2.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool,
    recognition: PropTypes.object
};
