import React from 'react';
import PropTypes from 'prop-types';

import OrganizerComponent from './OrganizerComponent';
import GuideTitle from '../components/GuideTitle';
import Overall from './../components/Overall';
import Unit from './../components/Unit';
import Schedule from './../components/Schedule';
import PageInfoPopover from '../components/PageInfoPopover';
import PrevUnit from '../components/PrevUnit';
import NextUnit from '../components/NextUnit';

export default class UnitOrganizer extends OrganizerComponent {

    constructor(props) {
        super(props);
        this.state = {
            title: ""
        }
    }

    render() {
        return (
            <main id="main_container" className="container-fluid">
                <PageInfoPopover video={null}/>
                <GuideTitle label="Unit Title"
                            placeholder="Untitled Unit Organizer"/>

                <div className="row gridline gridline-hr pt-1">

                    <div className="col-12">
                        <Overall stepnum="1"/>
                    </div>

                    <div className="row unit-nav">
                        <div className="col-12 col-md-4">
                            <PrevUnit stepnum="0"/>
                        </div>
                        <div className="col-12 col-md-4">
                            <h4>Current Unit</h4>
                            <p className="form-control-label-lg mb-1">{this.context.fileName}</p>
                        </div>
                        <div className="col-12 col-md-4">
                            <NextUnit stepnum="0"/>
                        </div>
                    </div>
                </div>

                <Unit/>

                <div className="row gridline gridline-hr pt-1">
                    <div className="col">
                        <Schedule stepnum="3"/>
                    </div>
                </div>
            </main>
        );
    }

}

UnitOrganizer.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool,
    fileName: PropTypes.string
};
