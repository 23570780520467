import $ from "jquery";
// require("imports?jQuery=jquery!figuration/dist/js/figuration");  // eslint-disable-line import/no-webpack-loader-syntax
//import $ from 'jquery';
window.jQuery = window.$ = $;
require("figuration/dist/js/figuration");  // eslint-disable-line import/no-webpack-loader-syntax

/* Should be called after page load to initialize UI elements */
export default function initUI() {
    if ($(document).CFW_Init)
        $(document).CFW_Init();

    headerModal();
    cardHeaders();
    formArrows();
    videoMenus();
}

/* Stop top header from adjusting when modal is triggered */
function headerModal() {
    var $header = $('header');
    $(document.body).on('scrollbarSet.cfw.modal', function() {
        var sW = $.fn.CFW_Modal.Constructor.prototype.measureScrollbar.apply();
        $header.css('padding-right', sW);
    });
    $(document.body).on('scrollbarReset.cfw.modal', function() {
        $header.css('padding-right', '');
    });
}

function cardHeaders() {
    $(document, '.unit-organizer .card-header')
        .on('beforeShow.cfw.collapse', '[data-cfw="collapse"]', function (e) {
            $(e.target).closest('.unit-organizer').addClass('open');
        })
        .on('afterHide.cfw.collapse', '[data-cfw="collapse"]', function (e) {
            $(e.target).closest('.unit-organizer').removeClass('open');
        });
}

function formArrows() {
    $(document, '.form-arrow .form-control')
        .on('focusin', function (e) {
            $(e.target).closest('.form-arrow').addClass('focus');
        })
        .on('focusout', function (e) {
            $(e.target).closest('.form-arrow').removeClass('focus');
        });
}

// Direction for video player dropdown menus
function videoMenus() {
    $(document, '[data-cfw="player"]').on('ready.cfw.player', function (e) {
        $(e.target).closest('[data-cfw="player"]').find('.player-caption-wrapper').addClass('dropup dropdown-menu-left');
        $(e.target).closest('[data-cfw="player"]').find('.player-script-wrapper').addClass('dropup dropdown-menu-left');
    });
    // Stop video when modal is closed
    $(document).on('beforeHide.cfw.modal', null, function(event) {
        $("[data-cfw='player']").CFW_Player('pause');
    });
}
