import React from 'react';
import OrganizerComponent from './OrganizerComponent';
import Question from '../components/Question';
import KeyTerms from '../components/KeyTerms';
import Qanda from '../components/Qanda';
import MainIdea from '../components/MainIdea';
import Related from '../components/Related';
import Overall from '../components/Overall';
import GuideTitle from '../components/GuideTitle';
import PageInfoPopover from '../components/PageInfoPopover';

export default class QuestionExp extends OrganizerComponent {

    render() {

        return (
            <main id="main_container" className="container-fluid">
                <PageInfoPopover video="question-exploration-tour"
                                 socExample="QEG_Soc.pdf"
                                 sciExample="QEG_Sci.pdf"
                />
                <GuideTitle label="Guide Title"
                            placeholder="Untitled Question Exploration Guide"/>
                <div className="row gridline gridline-hr gridline-md-vr">
                    <div className="col-md-5 pt-1 gridline-item gridline-hr">
                        <Question stepnum="1"/>
                    </div>
                    <div className="col-md-7 pt-1 gridline-item gridline-hr gridline-step2">
                        <KeyTerms stepnum="2"/>
                    </div>
                </div>
                <div className="row gridline gridline-hr pt-1">
                    <div className="col-12">
                        <Qanda stepnum="3"/>
                    </div>
                </div>
                <div className="row gridline gridline-hr pt-1">
                    <div className="col-12">
                        <MainIdea stepnum="4"/>
                    </div>
                </div>
                <div className="row gridline gridline-hr pt-1">
                    <div className="col-12">
                        <Related stepnum="5"/>
                    </div>
                </div>
                <div className="row gridline gridline-hr pt-1">
                    <div className="col-12">
                        <Overall stepnum="6"/>
                    </div>
                </div>
            </main>
        );
    }

}