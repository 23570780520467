
export function codeMirrorMultilineConfig(readOnly, placeholder) {
    return {
        readOnly: readOnly,
        scrollbarStyle: "null",
        lineWrapping: true,
        viewportMargin: Infinity,
        placeholder: placeholder,
        inputStyle: "contenteditable"
    };
}


export function codeMirrorSingleLineConfig(readOnly, placeholder) {
    return {
        readOnly: readOnly,
        extraKeys: {
            "Enter": function(editor) {
                editor.replaceSelection("")
            }
        },
        scrollbarStyle: "null",
        placeholder: placeholder,
        inputStyle: "contenteditable"
    };

}
