import React from 'react';
import OrganizerComponent from './OrganizerComponent';
import GuideTitle from '../components/GuideTitle';
import Question from '../components/Question';
import KeyTerms from '../components/KeyTerms';
import EventChains from '../components/EventChains';
import Answer from '../components/Answer';
import PageInfoPopover from '../components/PageInfoPopover';


export default class CauseEffect extends OrganizerComponent {

    render() {
        return (
            <main id="main_container" className="container-fluid">
                <PageInfoPopover video="cause-effect-tour"
                                 socExample="CEG_Soc.pdf"
                                 sciExample="CEG_Sci.pdf"
                />
                <GuideTitle label="Guide Title"
                            placeholder="Untitled Cause & Effect Guide"/>
                <div className="row gridline gridline-hr gridline-md-vr">
                    <div className="col-md-5 pt-1 gridline-item gridline-hr">
                        <Question stepnum="1"/>
                    </div>
                    <div className="col-md-7 pt-1 gridline-item gridline-hr gridline-step2">
                        <KeyTerms stepnum="2"/>
                    </div>
                </div>
                <EventChains/>
                <div className="row gridline gridline-hr pt-1">
                    <div className="form-group col-md-12">
                        <Answer stepnum="6"/>
                    </div>
                </div>
            </main>
        );
    }
}


