import React from 'react';
import TextAreaComponent from './TextAreaComponent';

// Simplified version of TextAreaComponent, using an <input> rather than <textarea> and simple label.
export default class InputComponent extends TextAreaComponent {

    constructor() {
        super();
        this.labelClassName = "h4";
    }

    renderHeader() {
        return null;
    }

    renderInput() {
        return (<div className="text-area firepad-form form-control form-control-lg" id={this.domId}/>);
    }

    getCurrentNode(){
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }
}
