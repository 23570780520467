import SttTextAreaComponent from "./SttTextAreaComponent";

export default class Related extends SttTextAreaComponent {

    constructor() {
        super();
        this.identifier = "related";
        this.className = "jumbotron bg-mustard-100 form-group";
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }
}
