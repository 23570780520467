import PropTypes from 'prop-types';
import SttTextAreaComponent from './SttTextAreaComponent';

export default class Cause extends SttTextAreaComponent {

    constructor(props) {
        super(props);
        this.identifier = "cause";
        this.labelClassName = "sr-only-md-up";
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.identifier;
    }

    renderHeader() {
        return null;
    }

}

Cause.propTypes = {
    parentNode: PropTypes.string.isRequired
};
