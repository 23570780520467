import React from 'react';
import { Component } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import VideoPlayer from "./VideoPlayer";

export default class UserSupport extends Component {

    render() {
        const videoURL  = "/video/corgi-intro.mp4";
        const ccURL     = "/video/CORGI-video-captions.vtt";
        const posterURL = "/img/CORGI-video-poster.png";
        return (
            <main id="main_container" className="container-fluid">
            <div className="container">
                <Tabs>
                    <TabList>
                        <Tab><h1>What is CORGI?</h1></Tab>
                        {/* <Tab><h1>Content Support</h1></Tab> */}
                         <Tab>
                             <Tab>
                                 <h1><a href="https://docs.google.com/document/d/1nSgMmauc3yugFKL6Y-LKI34vhlHMW0dKADYUyeSkksw">
                                     Technical Support
                                 </a></h1>
                             </Tab>
                         </Tab>
                    </TabList>

                    <TabPanel>
                        <div className="row" >
                            <div className="col-md-6 mt-md-1">
                                <VideoPlayer posterURL={posterURL} ccURL={ccURL} videoURL={videoURL} />
                            </div>
                            <div className="col-md-6 mt-md-1">
                                <h2>Intro to CORGI</h2>
                                <p>We took what’s already great about graphic organizers and made them more flexible and accessible, by bringing them online and adding lots of built-in tools and supports.
                                </p>
                                <p>Corgi marks the next generation of graphic organizers, helping students co-organize their learning in a digital, Google App environment. We want to see every student build higher-order thinking skills and become an expert learner.
                                </p>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                        </div>
                    </TabPanel>
                    {/* <TabPanel>

                    </TabPanel> */}
                    <TabPanel>
                        <h2><a href="https://docs.google.com/document/d/1nSgMmauc3yugFKL6Y-LKI34vhlHMW0dKADYUyeSkksw">
                                Updated CORGI 1 Manual
                            </a></h2>
                    </TabPanel>
                </Tabs>
            </div>
            </main>
        )
    }
}
