import { Component } from 'react';
import React from "react";

export default class BrandBar extends Component {

    render() {
        return (
        <div className="header-logo">
            <img src="/img/mark.svg" className="header-logo-img" alt="" />
            <span className="header-logo-title">Corgi</span>
            { /* <span class="badge header-logo-badge">beta</span> */}
            <span className="hide-sm-down">Co-Organize Your Learning</span>
        </div>
        );
    }

}
