import { Component } from 'react';
import ReactDOM from 'react-dom';

export default class DynamicComponent extends Component {

    componentDidMount() {
        // console.log("Mounted: ", this, ReactDOM.findDOMNode(this));
        this.tagForTTS(ReactDOM.findDOMNode(this));
    }

    componentDidUpdate() {
        // console.log("Updated: ", this, ReactDOM.findDOMNode(this));
        this.tagForTTS(ReactDOM.findDOMNode(this));
    }

    tagForTTS(node) {
        // Have to check since this can get called before texthelp toolbar has been loaded.
        if (window.$rw_parseNewSection && window.$rw_isPageLoaded())
            window.$rw_parseNewSection(node);
    }

}