import React from 'react';
import PropTypes from 'prop-types';
import TextAreaComponent from './TextAreaComponent';
import Autocomplete from 'react-autocomplete';


/**
 * A variant of AutocompleteArrow that uses react-autocomplete to allow selection from several options.
 * We can't use Realtime's bind() operation in conjunction with this, so need to approximate
 * it by tracking state & listening to change events.
 *
 * Don't use this component when data is read-only - use a regular AutocompleteArrow.
 */
export default class AutocompleteArrow extends TextAreaComponent {

    constructor(props) {
        super(props);
        this.identifier = props.identifier;
        this.className = props.className || "col-md-1_5 col-arrow";

        this.state = {};

        this.handleRTUpdate = this.handleRTUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    componentDidMount() {
        // Simply listen for change events and set state from them.
        this.connectorRef = window.firebase.database().ref(this.getCurrentNode());
        this.connectorRef.on("value", data => {
            if(data.val() !== this.state.value) {
                this.setState({
                    value: data.val()
                })
            }
        });
    }

    handleRTUpdate(event) {
        // Update state based on RT updates.
        this.setState({value: event.target.text}, () => {
            this.connectorRef.set(event.target.text);
        });
    }

    handleChange(event, value) {
        // Update state and RT view based on local change.
        this.setState({value: value}, () => {
            this.connectorRef.set(value);
        });
    }

    render() {
        const menuStyle = {
            zIndex: "10",
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 0',
            fontSize: '90%',
            position: 'fixed',
            overflow: 'auto',
            maxHeight: '50%'
        };
        const defaultItemStyle = {
            padding: '2px 6px',
            cursor: 'default'
        };
        const highlightItemStyle = {
            color: 'white',
            background: 'hsl(200, 50%, 50%)',
            padding: '2px 6px',
            cursor: 'default'
        };

        return (
            <div className={this.className}>
                <label htmlFor={this.domId} className="sr-only">{this.props.label}</label>
                <Autocomplete items={this.props.autocompleteOptions}
                              wrapperProps={{className: "form-arrow", style: null}}
                              inputProps={{id: this.domId, className: "form-control", placeholder: this.props.placeholder}}
                              menuStyle={menuStyle}
                              getItemValue={(item) => item}
                              renderItem={(item, isHighlighted) => (
                                  <div
                                      style={isHighlighted ? highlightItemStyle : defaultItemStyle}
                                      key={item}
                                  >{item}</div>)}
                              onSelect={(value)=>{this.setState({value: value}, () => this.connectorRef.set(value))}}
                              value={this.state.value}
                              onChange={this.handleChange}
                />
            </div>
        );
    }

    getCurrentNode() {
        return this.props.parentNode + "/" + this.identifier
    }

    renderHeader() {
        return null;
    }

}

AutocompleteArrow.propTypes = {
    identifier : PropTypes.string.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    autocompleteOptions: PropTypes.array.isRequired
};

