import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from "./Card";

/**
 * Displays a choice of the different CER types that are supported by the application.
 * When the user selects one, a new document of that type is created.
 */
export default class NewDocument extends React.Component {

    constructor() {
        super();

        this.createDoc = this.createDoc.bind(this);
        this.createUO = this.createUO.bind(this);
        this.createCET = this.createCET.bind(this);
        this.createQEG = this.createQEG.bind(this);
        this.createCT = this.createCT.bind(this);
        this.typeChosen = false; // Used to avoid a second click being registered while the first one is bring processed.
    }

    render() {
        return (

            <Fragment>

                <div id="CERs" className="pt-y group-hr">
                    <h2 className="text-body mb-1">Students &amp; Teachers: Create a new Content Enhancement Routine</h2>
                    <div className="card-grid">
                        <Card type={"cause-effect"} creator={this.createCET}/>
                        <Card type={"question-exp"} creator={this.createQEG} />
                        <Card type={"comparison"} creator={this.createCT} />
                    </div>
                </div>

                <div className="pt-y">
                    <h2 className="text-body mb-1">Teachers: Create a new organizer</h2>
                    <div className="card-grid">
                        <Card type={"unit-org-2"} creator={this.createUO} />
                    </div>
                </div>

            </Fragment>

        );

    }

    createCET() {
        return this.createDoc("cause-effect");
    }

    createQEG() {
        return this.createDoc("question-exp");
    }

    createCT() {
        return this.createDoc("comparison");
    }

    createUO() {
        return this.createDoc("unit-org-2");
    }

    createDoc(type) {
        if (!this.typeChosen) {
            this.typeChosen = true;
            this.props.createDocMethod(type); // OrganizerChoice.creatDoc
        } else {
            console.log("Type already chosen; ignoring second click on a create button");
        }
    }
}

NewDocument.propTypes = {
    createDocMethod: PropTypes.func.isRequired
};
