/* Speech to text handler object.
 * Wraps the browser's SpeechRecognition with some useful methods. */

export default class STT {

    constructor() {
        this.recognition = STT.getBrowserSpeechRecognitionObject();
        this.listening = false;
        this.resultListener = null;
        this.endListener = null;

        this.isSupported = this.isSupported.bind(this);
        this.startListening = this.startListening.bind(this);
        this.internalStartListening = this.internalStartListening.bind(this);
        this.stopListening = this.stopListening.bind(this);
        this.resultHandler = this.resultHandler.bind(this);
        this.endHandler = this.endHandler.bind(this);

        if (this.recognition) {
            this.recognition.onresult = this.resultHandler;
            this.recognition.onend = this.endHandler;
        }
    }

    isSupported() {
        return this.recognition !== null;
    }

    static getBrowserSpeechRecognitionObject() {
        const SpeechRec = typeof window !== 'undefined' && (
            window.SpeechRecognition ||
            window.webkitSpeechRecognition ||
            window.mozSpeechRecognition ||
            window.msSpeechRecognition ||
            window.oSpeechRecognition);

        // Consider as unavailable for Chrome on Android and iOS as it does not work through browser.
        // The system based STT has preference.
        const chromeMobile = navigator.userAgent.indexOf('Chrome') !== -1 &&
            navigator.userAgent.indexOf('Android') !== -1 &&
            !navigator.userAgent.match(/iPhone|iPod|iPad/);

        if (SpeechRec && !chromeMobile) {
            var recognition = new SpeechRec();
            recognition.continuous = true;
            recognition.interimResults = true;
            return recognition;
        } else {
            console.warn('SpeechRecognition is not supported by this browser.');
            return null;
        }
    }

    startListening(resultListener, endListener) {
        if (this.listening) {
            // If already listening, have to call stop, and then only start the new listener after we've received
            // confirmation that the previous listening session is actually stopped.
            console.log("Restarting STT in another place");
            this.recognition.onend = (event) => {
                this.endHandler(event);
                this.internalStartListening(resultListener, endListener);
                this.recognition.onend = this.endHandler;
            };
            this.recognition.stop();
        } else {
            console.log("Starting STT");
            // clean start
            this.internalStartListening(resultListener, endListener);
        }
    }

    internalStartListening(resultListener, endListener) {
        this.listening = true;
        this.resultListener = resultListener;
        this.endListener = endListener;
        this.recognition.start();
    }

    stopListening() {
        console.log("Stopping STT");
        this.recognition.stop();
    }

    resultHandler(event) {
        if (this.resultListener)
            this.resultListener(event);
    }

    endHandler(event) {
        if (this.endListener)
            this.endListener(event);
        this.endListener = null;
        this.listening = false;
    }

}