import SttTextAreaComponent from "./SttTextAreaComponent";

export default class Extensions extends SttTextAreaComponent {

    constructor() {
        super();
        this.identifier = "extensions";
        this.className = "jumbotron bg-green-100 form-group";
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }
}
