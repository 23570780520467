import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoButton from '../components/InfoButton';
import T from 'i18n-react';

export default class ComponentHeader extends Component {

    render() {
        return (
            <div className="row">
                <div className="col mb-0_5">
                    <h3 className="d-inline mb-0"><span className="hnum">{this.props.stepnum}</span>{T.translate(this.context.fileType+'.'+this.props.identifier+'.label')}</h3>
                    <InfoButton type={this.context.fileType} identifier={this.props.identifier} />
                </div>
            </div>
        );
    }

}

ComponentHeader.propTypes = {
    stepnum: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired
};

ComponentHeader.contextTypes = {
    fileType: PropTypes.string,
};
