
import SttTextAreaComponent2 from './SttTextAreaComponent2';
import PropTypes from "prop-types";

export default class SupportingAnswer extends SttTextAreaComponent2 {

    constructor(props,context) {
        super(props,context);

        this.identifier = "supportingAnswer";
        this.className = "firepad-form form-control";
        this.getCurrentNode = this.getCurrentNode.bind(this);
    }

    getCurrentNode() {
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier+"/"+this.props.id;
    }
}

SupportingAnswer.propTypes = {
    id: PropTypes.string.isRequired,
}
