import PairedCharacteristics from './PairedCharacteristics';

export default class LikeCharacteristics extends PairedCharacteristics {

    constructor(props) {
        super(props);

        this.charIdentifier = "likecharacteristics";
        this.catIdentifier = "likecategories";
        this.myStatus = "like";
        this.buttonText = "Find Like Pair";
        this.modalTitle = "Find a like pair";
        this.showBoth = false;
    }

}
