import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TopicList from './TopicList';
import InfoButton from './InfoButton';
import T from 'i18n-react';
import uniqueId from '../uniqueId';
import {codeMirrorMultilineConfig} from '../codeMirrorConfig';

// The "Unit Map" section, including paraphrase and list of main ideas and associated info.
export default class Unit extends Component {

    constructor(props) {
        super(props);
        this.identifier = "unit";
        this.aboutId = uniqueId();

    }

    render() {
        const type = this.context.fileType;
        return (
            <div className="row gridline gridline-hr py-1 group-unit">
                <div className="col-12 mb-1">
                    <h2 className="d-inline-block mb-0 mr-0_5"><span className="hnum">2</span>{T.translate(type+'.unitMap.label')}</h2>
                    <InfoButton type={type} identifier="unitMap" />
                </div>
                <div className="col-md-3 group-unit-main-flex">
                    <div className="row">
                        <div className="col mb-0_5">
                            <h3 className="d-inline-block mb-0">
                                {T.translate(type+".unitAbout.label")}
                            </h3>
                            <InfoButton type={type} identifier="unitAbout"/>
                        </div>
                    </div>
                    <div className="row group-unit-sub-flex">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor={this.aboutId} className="sr-only">{T.translate(type+'.unitAbout.label')}</label>
                                <div className="form-control firepad-form form-control-lg" id={this.aboutId}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="row visual-title">
                        <div className="col-4 pb-1">
                            <div className="row">
                                <div className="col">
                                    <div className="h3 d-inline mb-0 hide-sm-down" aria-hidden="true">{T.translate(type+'.mainIdea.label')}</div>
                                    <InfoButton type={type} identifier="mainIdea"/>
                                    <span className="small d-inline-block valign-middle pl-0_5 hide-md-up" aria-hidden="true">More information about<br /> {T.translate(type+'.mainIdea.label')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 pb-1">
                            <div className="row">
                                <div className="col">
                                    <div className="h3 d-inline mb-0 hide-sm-down" aria-hidden="true">{T.translate(type+'.questions.label')}</div>
                                    <InfoButton type={type} identifier="questions"/>
                                    <span className="small d-inline-block valign-middle pl-0_5 hide-md-up" aria-hidden="true">More information about<br /> {T.translate(type+'.questions.label')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 pb-1">
                            <div className="row">
                                <div className="col">
                                    <div className="h3 d-inline mb-0 hide-sm-down" aria-hidden="true">{T.translate(type+'.orgtype.label')}</div>
                                    <InfoButton type={type} identifier="orgtype"/>
                                    <span className="small d-inline-block valign-middle pl-0_5 hide-md-up" aria-hidden="true">More information<br/> about {T.translate(type+'.orgtype.label')}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <TopicList parentNode={this.getCurrentNode()}/>

                </div>
            </div>
        );
    }

    getCurrentNode(){
        return this.context.fileType + "/" + this.context.fileId + "/" + this.identifier;
    }

    componentDidMount() {
        const type = this.context.fileType;
        let placeholder = T.translate(type+'.unitAbout.placeholder');
        let firepadAboutRef = window.firebase.database().ref(this.getCurrentNode() + "/about");
        let codeMirrorTerm = window.CodeMirror(document.getElementById(this.aboutId),
            codeMirrorMultilineConfig(!this.context.canEdit, placeholder));
        window.Firepad.fromCodeMirror(firepadAboutRef, codeMirrorTerm, {
            richTextShortcuts: false,
            richTextToolbar: false
        });
    }

}

Unit.contextTypes = {
    fileType: PropTypes.string,
    fileId: PropTypes.string,
    canEdit: PropTypes.bool
};
